import React, {useState} from "react";
import "./Offers.css"
import axios from "axios";
function UserOffer({ firma, wojewodztwo,stanowisko, tryby, sposob,  opis, umiejetnosci, applied, id_}, visible, handleAplikowanie){
  const apiurl = "https://peopleofqualityback.azurewebsites.net"
  // const apiurl = "http://127.0.0.1:5000"
    const [dropped, setDropped] = useState(false);
    const [favorite, setFavorite] = useState(true);
    //const liked = JSON.parse(localStorage.getItem("liked")) ?? [];
    //const isLiked = liked.includes(id_);

    const handleToggleFavorite = (id) => {
      setFavorite(!favorite);
      
      // Use axios.post() here if needed
    };
    const DeleteOffer = () => {
      axios.post(`${apiurl}/api/DeleteOffer`, {"ID": id_})
        .then(response => {
          if (response.data.message === "Deleted") {
            window.alert("Oferta został usunięta");
          } else {
            window.alert("Wystąpił błąd przy usuwaniu");
          }
        })
        .catch(error => {
          console.error("An error occurred:", error);
          window.alert("Wystąpi");
        });
    };
    const offerBoxClass = `OfferBoxWelcome ${dropped ? 'act' : 'passive'}`;
    return (
      <div className={offerBoxClass}>
        <div>
        {visible ?
          <div>
            <div className="log_boxa"></div>
              <p>Oferta {id_}</p>
              <li>Firma: {firma}</li>
              <li>Województwa pracy: {wojewodztwo}</li>
              <li>Stanowisko: {stanowisko}</li>
              <li>Tryb pracy: {tryby}</li>
              <li>Sposób zatrudnienia: {sposob}</li>
              <li>Opis stanowiska: {opis}</li>
              <li>
              Użytkownicy:
            {applied && applied.length > 0 ? (
              <ul>
                {applied.split(", ").map((user, index) => (
                  <li key={index}>{user}</li>
                ))}
              </ul>
            ) : (
              <p>Żaden użytkownik nie zaaplikował</p>
            )}

              </li>
          <button className="DeleteButton" onClick={DeleteOffer}>Usuń oferte</button>
  
      
          </div> : <div></div>
        }
        </div>
        
      </div>
    )



};

export default UserOffer;
