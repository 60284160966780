import React, { useEffect, useState } from "react";
import NavbarMain from "../Components/NavbarMain";
import SidePanel from "../Components/side_panel";
import DropDownOffer from "../Components/Offers";
import axios from "axios";
import "./WelcomePage.css";
import { Link } from 'react-router-dom';
import Loading from "./loading";
function WelcomePage({ title, Login, isadmin}) {
  //const apiurl = "http://127.0.0.1:5000";
  const apiurl = "https://peopleofqualityback.azurewebsites.net"
  //const apiurl = "https://niggeria-ai.azurewebsites.net"
  var number = 10;
  const [isPressed, setIsPressed] = useState(true);
  const [offersIds, setOffersIds] = useState([])
  const [offersDisplayed, setOffersDisplayed] = useState(true);
  const [offersData, setOffersData] = useState([]);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [noOffers, setNoOffers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(true);
  useEffect(() => {
    document.title = title || 'People Of Quality';
    handleZnajdywanie({miejsce_zatrudnienia: ['Kujawsko-Pomorskie'],
      poziom
      : 
      ['kierownik'],
      spzatrudnienia
      : 
      ['kontrakt B2B'],
      tryb
      : 
      ['hybrydowy']});
    const handleResize = () => {
      setIsSidePanelVisible(window.innerWidth > 768); 
      const toggle_side_button = document.querySelector(".toggle-sidepanel-button");
      const toggle_button = document.querySelector(".hamburger-icon");
      if (window.innerWidth <= 768) {
        toggle_side_button.classList.add('active');
        toggle_button.classList.add('active')
      } else {
        toggle_side_button.classList.remove('active');
        toggle_button.classList.remove('active')
  }
    };

    handleResize(); 
    window.addEventListener("resize", handleResize); 

    return () => {
      window.removeEventListener("resize", handleResize); 
    };
  }, [title]);

  const handleZnajdywanie = async (filterData, number = null) => {
    
    setIsPressed(false);
    setOffersDisplayed(true);
    // console.log("klikłeś");
    // console.log("Filter Data:", filterData);

    try {
      const email = localStorage.getItem('email')
      const response = await axios.post(`${apiurl}/api/testfilter`, filterData);

      if (response.data.message === "Offers found successfully") {
        setNoOffers(false);
        setOffersIds(response.data.offer_ids);
        // console.log("Offer IDs:", response.data.offer_ids);
        await fetchOffersdetails(response.data.offer_ids.slice(0,10), email);
      } else if (response.data.message === "No offers found for the given criteria") {
        console.error("No offers found or alternative results:", response.data.message);
        setNoOffers(false);
        setOffersIds(response.data.offer_ids);
        await fetchOffersdetails(response.data.offer_ids.slice(0,10), email);
        // window.alert("Niestety obecnie nie ma ofert spełniających Twoje wymagania. Możesz zapoznać się z innymi ofertami, które mamy dla Ciebie przygotowane");
        // console.log("Offer IDs:", response.data.offer_ids);
      }
    } catch (error) {
      console.error("Error calling backend API:", error);
      setNoOffers(true);
    }
  };

  const fetchOffersdetails = async (offerIds, mail) => {
    try {
      const response = await axios.post(`${apiurl}/api/fetchIds`, { offer_ids: offerIds, email: mail });

      if (response.data.message === "got details") {
        console.log("FILTER DATA",response.data.data)
        setOffersData(response.data.data);
      } else {
        console.log("nie znalazłem ofert");
      }
    } catch (error) {
      console.error("Error fetching offer details:", error);
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;

      if (scrolled + windowHeight >= documentHeight - 50) {
        setIsFooterVisible(true);
      } else {
        setIsFooterVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const handleToggleSidePanel = () => {
    setIsSidePanelVisible(!isSidePanelVisible);
    const toggle_button = document.querySelector(".hamburger-icon");
    toggle_button.classList.toggle('active')
    const toggle_side_button = document.querySelector(".toggle-sidepanel-button")
    toggle_side_button.classList.toggle('active')
  };
  const handleLoadMore = async () => {
    const email = localStorage.getItem('email')
    number += 10;
    await fetchOffersdetails(offersIds.slice(number - 10,number), email);

  }


  return (
    <div className="welcomePage">
      <div className="navbar_grid_pages">
        <NavbarMain isadmin={isadmin} oferty={true}/>
      </div>
  
      
  
      {isSidePanelVisible && <SidePanel isPressed={isPressed} handleZnajdywanie={handleZnajdywanie} />}
      {isLoading ? <Loading /> : null}
  
      <div>
        <button className="toggle-sidepanel-button" onClick={handleToggleSidePanel}>
  <div className="hamburger-icon"></div>
</button>
    
      
          {offersDisplayed ? (
            noOffers ? (
              <div className={`no_ofert_msg ${isSidePanelVisible ? 'visible' : ''}`}>
                <h2>
                  <p>
                    Aktualnie nie posiadamy żadnych ofert pracy. Zarejestruj się i zostań członkiem People of Quality.
                  </p>
                </h2>
              </div>
            ) : (
              <>
              <div className="boxoffert">
                
                {offersData.map((offer) => (
                  <DropDownOffer className="left_side_box"
                    IsLiked={offer.Liked}
                    IsApplied={offer.Applied}
                    key={offer._id.$oid || offer.Firma + offer.NazwaStanowiska}
                    firma={offer.Firma}
                    nazwaStan={offer.NazwaStanowiska}
                    miasto={offer.Miasto}
                    wojewodztwo={offer.Wojewodztwa.join(", ")}
                    stanowisko={offer.Pozycja.join(", ")}
                    tryby={offer.Tryby.join(", ")}
                    sposob={offer.Sposob.join(", ")}
                    responsibilities={offer.Obowiązki}
                    wymagania={offer.Wymagania}
                    oferujemy={offer.Oferujemy}
                    umiejetnosci={Object.keys(offer.Skills).join(", ")}
                    id_={offer._id}
                    logofirmy={offer.image}
                    visible={true}
                  />
                ))}
              </div>
              <button onClick={handleLoadMore}></button>
              </>
            )
          ) : null}
        <footer className={`git_footer_welcome ${isFooterVisible ? 'visible' : ''}`}>
          <p>Copyright © 2023 peopleofquality.pl - All Rights Reserved.</p>
          <p className="polityka-link">
            <Link to="/Warunki">
              <span>Polityka prywatności</span>
              <br />
              <br />
            </Link>
            <Link to="/Regulamin">
              <span>Regulamin strony</span>
            </Link>
          </p>
        </footer>
      </div>
    </div>
  );
                }

export default WelcomePage;