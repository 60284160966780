import React, { useEffect, useState } from "react";
import NavbarMain from "../Components/NavbarMain";
import Edit_data from '../Components/images/edit_icon.png';
import './userprofile.css';
import axios from "axios";
import Ankieta from "../Components/Ankieta.js";
import '../Components/Ankieta.css';
import { Link, json } from 'react-router-dom';
import "./WelcomePage.css";
import DropDownOffer from "../Components/Offers.js";



function UserProfile({ title, user, isadmin}) {
    //const namea = localStorage.getItem('name') || 'N/A'
    //const lastame = localStorage.getItem('lastName') || 'N/A'
    const emaila = localStorage.getItem('email') || 'N/A'
    //const woja=  localStorage.getItem('woj') || 'N/A'
    

    const apiurl = "https://peopleofqualityback.azurewebsites.net"
    //const apiurl = "http://127.0.0.1:5000";

    const [userData, setUserData] = useState({
        Name: '',
        lastName: '',
        email: emaila,
        woj: '',
        cv_filename: '',
    });

    // Define getUserData inside the component
    const getUserData = () => {
        axios.get(`${apiurl}/api/GetUserData`, {
            params: { email: emaila }
        })
        .then(response => {
            if (response.status === 200) {
                setUserData(prevState => ({
                    ...prevState,
                    Name: response.data.name || '',
                    lastName: response.data.lastName || '',
                    woj: response.data.woj || '',
                }));
            } else {
                throw new Error("Error fetching user data");
            }
        })
        .catch(error => {
            console.error(error);
        });
    };
    const [editedUserData, setEditedUserData] = useState({
    Name: '',
    lastName: '',
    email: '',
    woj: '',
    });
    const [editingField, setEditingField] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [ankietaResponses, setAnkietaResponses] = useState(null);
    const [firmaResponses, setFirmaResponses] = useState(null);
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [liked, setLiked] = useState([]);
    const [likedIds, setLikedIds] = useState([]);

    const [applied, setApplied] = useState([]);
    const [appliedIds, setAppliedIds] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [cvFileName, setCvFileName] = useState(userData.cv_filename || 'Brak pliku');
    const [cv_filename_upd, setCv] = useState("")
    const maxFileSize = 1000000;
    useEffect(() => {
        getUserData();
        console.log(userData)
        document.title = title || 'People Of Quality';
        const storedUserData = JSON.parse(localStorage.getItem('userData'));
    if (storedUserData) {
      setUserData(storedUserData);
    }

        const userEmail = localStorage.getItem("email")
        fetchLikedOffers(userEmail);
        fetchAppliedOffers(userEmail);
        

        getCvFileName();
    }, [title]);

    useEffect(() => {
        console.log('Stan ankietaResponses:', ankietaResponses);
    }, [ankietaResponses]);
    useEffect(() => {
        console.log('Stan firmaResponses:', firmaResponses);
    }, [firmaResponses]);
    

    const handle_file_change = (event) => {
        const file = event.target.files[0];
        const allowedFileTypes = ["pdf", "doc", "docx"];
        const fileExtension = file && file.name ? file.name.split(".").at(-1) : null;

        if (!allowedFileTypes.includes(fileExtension)) {
          window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          return false;
        }
        if (file.size > maxFileSize) {
          window.alert(`File size too big. Files must be less than 1 MB`);
          return false;
        }
        console.log(fileExtension);
        setSelectedFile(file);
        setCvFileName(file.name);
      }
      const handleUploadCv = async () => {
        const apiUrl = `${apiurl}/api/uploadCV`;
        const email = localStorage.getItem("email");
      
        const formData = new FormData();
        formData.append("email", email);
        formData.append("cvfile", selectedFile);
      
        axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(response => {
            //console.log(response);
            if (response.status === 200) {
              const filename = response.data.filename;
              //console.log("filename",filename);
              setCv(filename)
              //const updatedUserData = { ...userData, "cv_filename": filename };
              //localStorage.setItem('cv_filename', filename);
              //console.log(updatedUserData);
             // setUserData(updatedUserData);
            } else {
              throw new Error("Failed to upload CV");
            }
          })
          .catch(error => {
            console.error(error);
          });
      };




    
    const getCvFileName = async () => {
        try {
            const email = localStorage.getItem("email");
            const response = await axios.post(`${apiurl}/api/getData`, {"email": email});
            if (response.data.message === "got cv") {
                setCv(response.data.data);
                
            } else {
                console.log("Brak polubionych ofert.");
            }
        } catch (error) {
            console.error("Error fetching liked offers:", error);
        }
    };
    const handleDropDown = ()=>{

    }

    const voivodeships = [
        { value: "dolnoslaskie", name: "Dolnośląskie" },
        { value: "kujawsko-pomorskie", name: "Kujawsko-Pomorskie" },
        { value: "lubelskie", name: "Lubelskie" },
        { value: "lubuskie", name: "Lubuskie" },
        { value: "lodzkie", name: "Łódzkie" },
        { value: "malopolskie", name: "Małopolskie" },
        { value: "mazowieckie", name: "Mazowieckie" },
        { value: "opolskie", name: "Opolskie" },
        { value: "podkarpackie", name: "Podkarpackie" },
        { value: "podlaskie", name: "Podlaskie" },
        { value: "pomorskie", name: "Pomorskie" },
        { value: "slaskie", name: "Śląskie" },
        { value: "swietokrzyskie", name: "Świętokrzyskie" },
        { value: "warminsko-mazurskie", name: "Warmińsko-Mazurskie" },
        { value: "wielkopolskie", name: "Wielkopolskie" },
        { value: "zachodniopomorskie", name: "Zachodniopomorskie" }
    ];

    const handleDataEdition = (field) => {
        console.log(field)
        setEditingField(field);
        setIsEditing(true);
        // Ensure editedUserData has the latest value from userData for the field being edited
        // setEditedUserData({ ...editedUserData, [field]: userData[field] });
    };
    const handleFieldChange = (event) => {
        const { name, value } = event.target; // Destructure name and value from the event target
        setEditedUserData({ ...editedUserData, [name]: value });
    };
    const handleSaveChanges = (field) => {
        setEditingField(null);
        setIsEditing(false);
        saveChangesToBackend(field, userData[field]);
    };

    const handleUserDataChange = (field, value) => {
        const newUserData = { ...userData, [field]: value };
        setUserData(newUserData);
      };
    

    // Save changes to both backend and local state
    const saveChangesToBackend = async (field) => {
        const value = editedUserData[field];
        try {
            const apiUrl = `${apiurl}/api/ChangeData`;
            const data = {
                email: userData.email,
                name : value,
                "editedField": field
                        };
            console.log("data",data) // Assuming you identify users by their email
            const response = await axios.post(apiUrl, data);
            if (response.status === 200) {
                // Update userData state with new value
                setUserData({ ...userData, [field]: value });
                // Optionally, update localStorage
                // Reset editing state
                setEditingField(null);
                setIsEditing(false);
            } else {
                throw new Error("Failed to change the data");
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleLanguageChange = (event) => {
        const newValue = event.target.value;
        setEditedUserData({ ...editedUserData, woj: newValue });
    
    };
    
    const getAnkieta = () => {
        
        const userEmail = userData.email;
        axios.get(`${apiurl}/api/GetAnkieta?email=${userEmail}`)
        .then(response => {
            
            setAnkietaResponses(response.data.ankietaResponses);
        })
        .catch(error => {
            console.error('blad pobierania', error);
        });
    };

    const getFirma = () => {
        console.log('zaczynam pobieranie danych z ankiety firmy');
        const userEmail = userData.email;
        axios.get(`${apiurl}/api/GetFirma?email=${userEmail}`)
        .then(response => {
            console.log('Odpowiedzi z ankiety o firmie:', response.data.firmaResponses);
            setFirmaResponses(response.data.firmaResponses);
        })
        .catch(error => {
            console.error('blad pobierania', error);
        });
        console.log('zakonczono pobieranie danych z ankiety firmy');
    };



    const fetchLikedOffers = async (email) => {
        try {
            const response = await axios.post(`${apiurl}/api/getLikes`, {"email": email});

            if (response.data.message === 'got ids') {
                setLikedIds(response.data.data);

                fetchLikedDetails(response.data.data);
            } else {
                console.log("Brak polubionych ofert.");
            }
        } catch (error) {
            console.error("Error fetching liked offers:", error);
        }
    }


    const fetchLikedDetails = async (likeds) => {
        try {
            const email = localStorage.getItem("email")
            const response = await axios.post(`${apiurl}/api/fetchIds`, {"offer_ids": likeds, "email": email});

            if (response.data.message === 'got details') {
                console.log(response.data.data);
                setLiked(response.data.data);
                console.log(liked)
            } else {
                console.log("Brak polubionych ofert.");
            }
        } catch (error) {
            console.error("Error fetching liked offers:", error);
        }
    }


    const fetchAppliedOffers = async (email) => {
        try {
            const response = await axios.post(`${apiurl}/api/getApplies`, {"email": email});

            if (response.data.message === 'got ids') {

                console.log("applied ", response.data.data);
                setAppliedIds(response.data.data);
                fetchAppliedDetails(response.data.data);
            } else {
                console.log("Brak polubionych ofert.");
            }
        } catch (error) {
            console.error("Error fetching liked offers:", error);
        }
    }


    const fetchAppliedDetails = async (aplies) => {
        try {
            console.log("Applied offers");
            console.log(aplies);
            const email = localStorage.getItem("email")
            const response = await axios.post(`${apiurl}/api/fetchIds`, {"offer_ids": aplies, "email": email});

            if (response.data.message === 'got details') {
                console.log(response.data.data);
                setApplied(response.data.data);
                console.log(applied);
            } else {
                console.log("Brak polubionych ofert.");
            }
        } catch (error) {
            console.error("Error fetching liked offers:", error);
        }
    }
      
    const handleRefreshData = () => {
        getAnkieta();
        getFirma();
    };
    const handleEditClick = (field) => {
        handleDataEdition(field);
    };
    return (
                        <div className="UserProfile">
                            <NavbarMain isadmin={isadmin} />
                            <div className="left_userprofile_wizytowka"> 
                            <div className="wizytowka_userprofile" >
                                
                                <div className="Dane_userprofile">
                                <ul>
                                        <span className="header_user">Imię</span>
                        <li>
                            {editingField === "Name" ? (
                                <div><input
                                type="text"
                                name="Name" // Make sure this matches your state's property
                                value={editedUserData.Name}
                                onChange={handleFieldChange} // Adjust handleFieldChange to use event.target.name
                            />
                            <button onClick={() => saveChangesToBackend("Name")}>Zapisz zmiany</button>
                            </div>  
                                
                            
                            ) : (
                            <div className="field_box">
                                <div className="field_box">{userData.Name}</div>
                                <img
                                src={Edit_data}
                                alt="przycisk_edycji_user"
                                className="przycisk_edycji_user"
                                onClick={() => handleDataEdition("Name")}
                                />
                            </div>
                            )}
                        </li>
                        <span className="header_user">Nazwisko</span>
                        <li>
                            {editingField === "lastName" ? (
                                <div>
                                <input
                                    type="text"
                                    name= "lastName"
                                    value={editedUserData.lastName}
                                    onChange={handleFieldChange}
                                />
                                <button onClick={() => saveChangesToBackend("lastName")}>Zapisz zmiany</button>
                                </div>
                            ) : (
                                <div className="field_box">
                                <div className="field_box">{userData.lastName}</div>
                                <img
                                src={Edit_data}
                                alt="przycisk_edycji_user"
                                className="przycisk_edycji_user"
                                onClick={() => handleDataEdition("lastName")}
                            />
                                </div>
                            )}
    
                        </li>
                        <span className="header_user">E-mail</span>
                        <li>
                                <div className="field_box">{userData.email}</div>
                        </li>
                        
                        <span className="header_user">Preferowane województwo</span>

                        <li>
                            {editingField === "woj" ? (
                                <div>
                                    <label htmlFor="language"></label>
                                    <select id="language" value={editedUserData.woj} onChange={handleLanguageChange}>
                                        {voivodeships.map((voivodeship) => (
                                            <option key={voivodeship.value} value={voivodeship.value}>
                                                {voivodeship.name}
                                            </option>
                                        ))}
                                    </select>
                                    <button onClick={() => saveChangesToBackend("woj")}>Zapisz zmiany</button>
                                </div>
                            ) : (
                                <div className="field_box">
                                <span>{userData.woj}</span>
                                <img
                                src={Edit_data}
                                name="woj"
                                alt="przycisk_edycji_user"
                                className="przycisk_edycji_user"
                                onClick={() => handleDataEdition("woj")}
                            />
                            </div>
                            )}
                        </li>
                    </ul>
                               
                </div>
            </div>
            </div>
            <div className="left_userprofile_ankieta">
            <div className="Cv_ankieta-userprofile">
                <ul>
                <input 
                    type="file"
                    id="cvFile"
                    accept=".pdf,.doc,.docx"
                    onChange={handle_file_change}
                    />
                    
                    <button className="przyciski" onClick={handleUploadCv}>Załaduj CV</button>
                    <p>Załadowane CV: {cv_filename_upd}</p>
                    <li>
                        <div>
                            <div className="ankieta_rozsunac">
                            <h2>Ankieta
                            <button className="przycisk_ankieta" onClick={handleRefreshData}>Załaduj odpowiedzi z ankiety</button></h2>
                            
                                {ankietaResponses && Object.keys(ankietaResponses).length > 0 && (
                                    <div>
                                        <h2 className="ankieta_rozsunac">Poziom umiejętności z ankiety:</h2>
                                        <ul className="ankieta_rozsunac">
                                            {Object.entries(ankietaResponses).map(([question, response], index) => (
                                                <li key={index}>
                                                    {question}: <a className="star-yellow">{Array.from({ length: response }, (_, i) => "★").join("")}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            {firmaResponses && Object.keys(firmaResponses).length > 0 && (
                                    <div>
                                        <h2>Klienci z którymi współpracowałeś:</h2>
                                        <ul>
                                        {firmaResponses['Współpraca z firmami'].map((response, index) => (
                                            <li key={index}>
                                                    {response}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                            )}
                            </div>
                            <div>
                            <Link to="/Ankieta"  className="napis-ankieta"><br />Kliknij tutaj aby zmienić swoje odpowiedzi do ankiety</Link>
                            </div>
                        </div>
                    </li>
                    
                </ul>
            </div>
            </div>
            <div className="right_userprofile">
        <div className="OfferContainer" onClick={handleDropDown}>
            <h2 className="naglowek_user">Oferty na które aplikowałeś ({applied.length}):</h2>
            <div className="margin_offer">
            {applied.map((offer) => (
                <DropDownOffer
                  key={offer._id.$oid}
                  firma={offer.Firma}
                  miasto={offer.Miasto}
                  nazwaStan={offer.NazwaStanowiska}
                  wojewodztwo={offer.Wojewodztwa.join(", ")}
                  stanowisko={offer.Pozycja.join(", ")}
                  tryby={offer.Tryby.join(", ")}
                  sposob={offer.Sposob.join(", ")}
                  responsibilities={offer.Obowiązki}
                wymagania={offer.Wymagania}
                oferujemy={offer.Oferujemy}
                  umiejetnosci={Object.keys(offer.Skills).join(", ")}
                  id_={offer._id}
                  visible={true}
                  IsLiked={offer.Liked}
                  IsApplied={true}
                  logofirmy={offer.image}
                  
                />
              ))}
            </div>
    </div>

    <div className="OfferContainer_liked">
    
        <h2 className="naglowek_user_liked">Polubione oferty ({liked.length}):</h2>
        <div className="margin_offer">
              {liked.map((offer) => (
                <DropDownOffer
                  key={offer._id.$oid}
                  firma={offer.Firma}
                  miasto={offer.Miasto}
                  nazwaStan={offer.NazwaStanowiska}
                  wojewodztwo={offer.Wojewodztwa.join(", ")}
                  stanowisko={offer.Pozycja.join(", ")}
                  tryby={offer.Tryby.join(", ")}
                  sposob={offer.Sposob.join(", ")}
                  responsibilities={offer.Obowiązki}
                    wymagania={offer.Wymagania}
                    oferujemy={offer.Oferujemy}
                  umiejetnosci={Object.keys(offer.Skills).join(", ")}
                  id_={offer._id}
                  visible={true}
                  IsApplied={offer.Applied}
                  IsLiked={offer.Liked}
                  logofirmy={offer.image}
                  
                />
              ))}
           
           </div>   
    
    
</div>

</div>
<footer className={`git_footer_user ${isFooterVisible ? 'visible' : ''}`}>
      <p>Copyright © 2023 peopleofquality.pl - All Rights Reserved.</p>
      <p className="polityka-link"><Link to="/Warunki"><br /><span>Polityka prywatności</span><br /><br /></Link><Link to="/Regulamin"><span> Regulamin strony</span></Link> </p>
    </footer>
</div>

    );
              }


export default UserProfile;