import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from "axios";
import './Ankieta.css';
import NavbarMain from "./NavbarMain";

function Ankieta({ title, onAnkietaSubmit, ankietaResponses, firmaResponses, isadmin  }) {
  useEffect(() => {
    document.title = title || 'Ankieta';
  }, [title]);
  const apiurl = "https://peopleofqualityback.azurewebsites.net"
  // const apiurl = "http://127.0.0.1:5000"
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [responses, setResponses] = useState(ankietaResponses || {});
  const [firmaWspolpraca, setFirmaWspolpraca] = useState('');
  const [listaFirm, setListaFirm] = useState([]);
  const [isDiv2Expanded, setIsDiv2Expanded] = useState(false);
  const questions = [
   "1) Control Plan",
   "2) Flow Chart",
   "3) QRQC",
   "4) PPAP",
   "5) 8D",
   "6) FMEA",
   "7) FTA",
   "8) SPC",
   "9) MSA",
   "10) A3",
   "11) Pareto",
   "12) Ishikawa",
   "13) 5Why",
   "14) PPM & TLR",
   "15) 5W2H",
   "16) PDCA",
   "17) FPD",
   "18) IATF",
   "19) Run at Rate",
  ];
  

  const handleFirmaWspolpracaChange = (event) => {
      setFirmaWspolpraca(event.target.value);
  };
  const handleAddFirma = () => {
    if (firmaWspolpraca.trim() !== '') {
    setListaFirm(prevListaFirm => [...prevListaFirm, firmaWspolpraca]);
    setFirmaWspolpraca('');
    setIsDiv2Expanded(true); 
    }
  };

  const handleResponseChange = (questionIndex, rating) => {
    setResponses({ ...responses, [questionIndex]: rating });
    
  }
  const copy_user = (email) => {
    axios.post(`${apiurl}/api/copy`, {"email": email}, {
      headers: {
        'Content-Type': 'application/json',
        },
      })
  }
  const navigate = useNavigate();

  const handleAnkietaSubmit = () => {
    handleAddFirma();
    setListaFirm([...listaFirm, firmaWspolpraca]);
    const ankietaData = {
      "ControlPlan": responses[0] || 0,
      "FlowChart": responses[1] || 0,
      "QRQC": responses[2] || 0,
      "PPAP": responses[3] || 0,
      "8D": responses[4] || 0,
      "FMEA": responses[5] || 0,
      "FTA": responses[6] || 0,
      "SPC": responses[7] || 0,
      "MSA": responses[8] || 0,
      "A3": responses[9] || 0,
      "Pareto": responses[10] || 0,
      "Ishikawa": responses[11] || 0,
      "5Why": responses[12] || 0,
      "PPM & TLR": responses[13] || 0,
      "5W2H": responses[14] || 0,
      "PDCA": responses[15] || 0,
      "FPD": responses[16] || 0,
      "IATF": responses[17] || 0,
      "RunAtRate": responses[18] || 0,
      email: localStorage.getItem("email"),
    };
    const ankietaFirma = {
    "Współpraca z firmami": [...listaFirm, firmaWspolpraca],
    email: localStorage.getItem("email"),
    };

    axios.post(`${apiurl}/api/postAnkieta`, ankietaData, {
    headers: {
      'Content-Type': 'application/json',
      },
    })
    axios.post(`${apiurl}/api/postFirma`, JSON.stringify(ankietaFirma), {
      headers: {
        'Content-Type': 'application/json',
        },
    })
    .then((response) => {
      console.log(response.data);
      if (response.data.message === "User's questionnaire updated successfully") {
        const email = localStorage.getItem("email")
        copy_user(email)
        console.log("Ankieta wysłana pomyślnie");
        if (typeof onAnkietaSubmit === 'function') {
          onAnkietaSubmit(responses);
        }
        navigate('/Oferty', { state: { showAnkieta: true } });
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
  
  const handleRemoveFirma = (index) => {
    const updatedListaFirm = [...listaFirm];
    updatedListaFirm.splice(index, 1);
    setListaFirm(updatedListaFirm);
  };

  return (
    <div className="AnkietaPage">
      <NavbarMain isadmin = {isadmin}/>
      <div className="poziom_gwiazdki">
      <h2>Określ poziom znajomości narzędzi jakościowych według skali:</h2>
        {[1, 2, 3, 4, 5].map((poziom) => (
          <p className="poziom_tekst"  key={poziom}>
            {[...Array(poziom)].map((_, index) => (
              <span key={index} className="star-yellow">
                ★
              </span>
            ))}
            {[...Array(5 - poziom)].map((_, index) => (
              <span key={index} className="star">
                ★
              </span>
            ))}
            {` poziom ${poziom <= 1 ? 'brak znajomości' : poziom <= 2 ? 'podstawowy' : poziom <= 3 ? 'średnio zaawansowany' : poziom <= 4 ? 'zaawansowany' : 'ekspert'}`}
          </p>
        ))}</div>
        <div className="questions-container">
          {questions.map((question, index) => (
            <div key={index} className="question-item">
              <p>{question}</p>
              <div className="rating">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <label key={rating}>
                    <input
                      type="radio"
                      name={`question-${index}`}
                      value={rating}
                      checked={responses[index] === rating}
                      onClick={() => handleResponseChange(index, rating)}
                    />
                    <span className={`star ${rating <= responses[index] ? 'yellow' : ''}`} key={rating}>★</span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        
        </div> 
        <div className={`div2_ankieta ${isDiv2Expanded ? 'expanded' : ''}`}>
          <div className="srodek_div2">
          <p> Wymień Klientów z którymi współpracowałeś:</p>
          {listaFirm.map((firma, index) => (
            <div key={index} className="firma-item">
              <input className="wpisane_ankieta"  value={firma} readOnly />
              <button className="przyciski_usun" onClick={() => handleRemoveFirma(index)}>
                X
              </button>
            </div>
          ))}
          <div className="firma-item">
            <input className="TekstAnkiety"
              type="text"
              value={firmaWspolpraca}
              onChange={handleFirmaWspolpracaChange}
            />
            <button className="przyciski_dodaj" onClick={handleAddFirma}>
              Dodaj firmę
            </button>
          </div>
          </div>
          </div>
      <div>
      <button className="przyciski_przeslij"onClick={handleAnkietaSubmit}>
        Prześlij odpowiedzi
      </button>
      </div>
      <footer className={`git ${isFooterVisible ? 'visible' : ''}`}>
      <p>Copyright © 2023 peopleofquality.pl - All Rights Reserved.</p>
      <p className="polityka-link"><Link to="/Warunki"><span>Polityka prywatności</span><br /><br /></Link><Link to="/Regulamin"><span> Regulamin strony</span></Link> </p>
    </footer>
    </div>
 
  );
}

export default Ankieta;