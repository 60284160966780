import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./Navbar.css";
import PoQ_logo from "./images/LOGO_bez_tla.png"
import Profile from './images/user.png'
import logout from './images/logout.png'
import Loading from "../pages/loading";
function NavbarMain({isadmin, oferty}) {
  const apiurl = "https://peopleofqualityback.azurewebsites.net"
  /// const apiurl = "http://127.0.0.1:5000"
  const [isTextVisible, setTextVisibility] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [open, setopen] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const user_email = localStorage.getItem('email');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 700);
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    // tutaj do uzgodnienia jaka szerokosc max ustawiamy, mozemy zostać póki co przy tej
    const handleResize = () => {
      setIsNavbarVisible(window.innerWidth > 768); 
    };

    handleResize(); 
    window.addEventListener("resize", handleResize); 

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout); 
    };
  }, []);

  const handleToogleNavbarVisible = () => {
    setIsNavbarVisible(!isNavbarVisible)
    const toggle_button_burger = document.querySelector(".navburger-icon");
    toggle_button_burger.classList.toggle('active')
  }
  const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem('woj');
        localStorage.removeItem('lastName');
        window.location.href = '/';
      };

  const handleUserProfile = () => {
    window.location.href = '/UserProfile';
  };
  const handleOnas = () => {
    window.location.href = '/O-nas';
  };
  const handleOferty = () => {
    window.location.href = '/Oferty';
  }
  const Zmiana = () => {
    window.location.href = '/Zmiana'
  }
  const ToLogin = () => {
    window.location.href = '/Login'
  }
  const ToRegist = () => {
    window.location.href = '/Rejestracja'
  }
  const ToFind = () => {
    window.location.href = '/ToFind'
  }
  const Kontakt = () => {
    window.location.href = '/Kontakt';
  }
  const Dodaj = () => {
    window.location.href = '/Dodaj';
  }
  const ListaU = () => {
    window.location.href = '/ListaUzytkownikow';
  }
  const ListaO = () => {
    window.location.href = '/Uzytkownicy';
  }
  const ListaA = () => {
    window.location.href = '/Najlepsi';
  }
  return (
    <>
    <button className="toggle-navbar-button" onClick = {handleToogleNavbarVisible}><div className="navburger-icon"></div></button>
    {isLoading ? <Loading /> : <></>}
    <div className="Navbar">
    <div className="div1">
      <Link to= "/Oferty"> <img src={PoQ_logo} alt="PoQ Logo" className="logo" /></Link>
      </div>
      {isNavbarVisible ? <>
      <div className="div2" onClick={handleOferty}>
        OFERTY PRACY
        </div>
      <div className="div3" onClick={handleOnas}>O NAS</div>
      <div className="div4" onClick={Kontakt}>KONTAKT</div>
      
      {isLoggedIn ? (
        <>
          <div className="div6_hidden">Szukasz Pracownika? <br /> NAPISZ DO NAS</div>
        </>
      ) : (
        <>
          <div className="div5" onClick={ToRegist}>Szukasz pracy? <br /> ZAREJESTRUJ SIĘ</div>
          <div className="div6" onClick={ToFind}>Szukasz Pracownika? <br /> NAPISZ DO NAS</div>
        </>
      )}
      {isLoggedIn ? (
        <div className="div7">
          <div className="menu-container"> 
            <div className="menu-trigger" onClick={() => {setopen(!open)}}>
            <img src={Profile} alt = "zdj prof"  className="user_photo" />
            </div>
            <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`}>
              <h3 className="email_linijka">{user_email}<br/></h3>
              {isadmin ?
              <ul>
                <DropdownItem img = {Profile} text= {"Mój profil"} onClick={handleUserProfile}/>
                <DropdownItem img = {logout} text= {"Zmień hasło"} onClick={Zmiana}/>
                <DropdownItem img = {logout} text = {"Dodaj oferte"} onClick={Dodaj}/>
                <DropdownItem img = {logout} text = {"Lista Użytkowników"} onClick={ListaU}/>
                <DropdownItem img = {logout} text = {"Oferty po użytkownikach"} onClick={ListaO}/>
                <DropdownItem img = {logout} text = {"Najlepsi kandydaci"} onClick={ListaA}/>
                <DropdownItem img = {logout} text = {"Wyloguj się"} onClick={handleLogout}/>
              
              </ul> : <ul>
                <DropdownItem img = {Profile} text= {"Mój profil"} onClick={handleUserProfile}/>
                <DropdownItem img = {logout} text= {"Zmień hasło"} onClick={Zmiana}/>
                <DropdownItem img = {logout} text = {"Wyloguj się"} onClick={handleLogout}/>


              
              </ul>
              }

              </div>
            </div>   
        </div>
      ) : (
        isTextVisible && (
          <div className="div7" onClick={ToLogin}>
            <div className="homelogin">
             Zaloguj się
            </div>
          </div>
        )
      )}
      </>
      : <div></div>}
      
      
    </div>
    
    </>
  );
}

function DropdownItem(props) {
  return (
    <li className="dropdownItem" key={props.text} onClick={props.onClick}>
      <img src={props.img} alt={props.text} />
      <span style={{width: 10 + "px", color: "black"}}>{props.text}</span>
    </li>
  );
}


export default NavbarMain;
