import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Offers.css";
import { Link } from "react-router-dom";
import Loading from "../pages/loading";

function DropDownOffer({ firma, wojewodztwo, stanowisko, tryby, sposob, id_, IsApplied, IsLiked, responsibilities, wymagania, oferujemy, logofirmy, nazwaStan, miasto }) {
    const apiurl = "https://peopleofqualityback.azurewebsites.net";
    const [favorite, setFavorite] = useState(false);
    const [applied, setApplied] = useState(false);
    const [appliedSuccessfully, setAppliedSuccessfully] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isLoggedIn, setisLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const email = localStorage.getItem("email");
        setIsUserLoggedIn(Boolean(email));
        setApplied(IsApplied);
        setFavorite(IsLiked);

        const token = localStorage.getItem('token');
        setisLoggedIn(!!token);
    }, [id_]);

    const handleToggleFavorite = async (id_) => {
        setFavorite((prevFavorite) => !prevFavorite);

        try {
            const email = localStorage.getItem("email");
            const response = await axios.post(`${apiurl}/api/LikeOffer`, {
                email: email,
                id: id_,
                favorite: !favorite,
            });

            if (response.data.message === "Operation successful") {
                console.log("Offer added to favorites");
            }
        } catch (error) {
            console.error("Error during the request:", error);
        }
    };

    const checkStatus = async () => {
        try {
            const email = localStorage.getItem("email");
            const response = await axios.post(`${apiurl}/api/CheckStatus`, {
                email: email,
                id: id_,
            });

            if (response.data.message === "Offer found") {
                window.alert("Poprawnie zaaplikowałeś na daną ofertę");
                setApplied(true);
            } else {
                setApplied(false);
            }
        } catch (error) {
            console.error("Error during the request:", error);
        }
    };

    const handleAplikowanie = async (id_) => {
        try {
            setIsLoading(true);
            const email = localStorage.getItem("email");
            if (appliedSuccessfully) {
                return;
            }

            const response = await axios.post(`${apiurl}/api/ApplyOffer`, {
                email: email,
                id: id_,
                firma: firma,
            });

            if (response.data.message === "Operation successful") {
                window.alert("Poprawnie zaaplikowałeś na daną ofertę");
                setApplied((prevApplied) => !prevApplied);
                setAppliedSuccessfully(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error during the request:", error);
            setIsLoading(false);
        }
    };

    const handleDropdownToggle = () => {
        setVisible(!visible);
    };

    return (
        <div className={`OfferBoxWelcome_passive ${visible ? "OfferBoxWelcome_act" : ""}`}>
            
            <div className={`scrollbarOffer ${visible ? "scrollbarOffer_act" : ""}`}>
                {isLoading ? <Loading /> : <></>}
                <div className="log_boxa">
                    <img src={`data:image/png;base64,${logofirmy}`} alt="firmalogo" className="logoOfferta" />
                    <div className="log_boxa_info">
                        <li><strong>FIRMA</strong>: {firma}</li>
                        <li><strong>LOKALIZACJA</strong>: {wojewodztwo}</li>
                        <li><strong>NAZWA STANOWISKA</strong>: {nazwaStan}</li>
                    </div>
                    
                </div>
                {visible &&
                    <div className="offers">
                        <div className="right_side_offer">
                            <ul>
                                <li><strong>MIASTO</strong>: {miasto}</li>
                                <li><strong>POZIOM STANOWISKA</strong>: {stanowisko}</li>
                                <li><strong>RODZAJ UMOWY</strong>: {sposob}</li>
                                <li><strong>TRYB PRACY</strong>: {tryby}</li>
                                <li>
                                    <strong>ZAKRES OBOWIĄZKÓW</strong>:
                                    <ul>
                                        {responsibilities && responsibilities.map((item, index) => (
                                            <li key={index}>- {item}</li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <strong>WYMAGANIA</strong>:
                                    <ul>
                                        {wymagania && wymagania.map((item, index) => (
                                            <li key={index}>- {item}</li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <strong>CO OFERUJEMY</strong>:
                                    <ul>
                                        {oferujemy && oferujemy.map((item, index) => (
                                            <li key={index}>- {item}</li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className="buttons_offer">
                        {isLoggedIn ? (
                            <>
                                <button className="aplikujbuttonOffer" onClick={() => handleAplikowanie(id_)}>
                                    {applied ? "Zaaplikowano" : "Aplikuj"}
                                </button>
                                <button className="likeButtonWe" onClick={() => handleToggleFavorite(id_)}>
                                    {favorite ? "❤️" : "Dodaj do polubionych"}
                                </button>
                            </>
                        ) : (
                            <Link to="/Login">
                                <button className="log_to_like">Zaloguj się aby zaaplikować</button>
                            </Link>
                        )}
                        <button className="aplikujbuttonOffer" onClick={handleDropdownToggle}>
                            {visible ? "Zwiń" : "Rozwiń"}
                        </button>
                    </div>
        </div>
    );
}

export default DropDownOffer;
