import React from "react";
import './loading.css'
function Loading() {


    return (<div className="LoadingScreen">
        <div className="spinner">

        </div>

    </div>);
}


export default Loading;
