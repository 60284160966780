import React, {useState, useEffect} from "react";
import "./side_panel.css"
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function Side_panel( {isPressed, handleZnajdywanie}) {
    const [expanded, setExpanded] = useState(true); 
    const [UserApplyData, setApplyData] = useState({
        spzatrudnienia: [],
        miejsce_zatrudnienia: [],
        poziom: [],
        tryb: [],
      });
    const sposob_zatrudnienia = [
        { value: "Kontrakt B2B", name: "Kontrakt B2B"},
        {value: "Zlecenie", name: "Zlecenie"},
        {value: "Umowa o pracę", name: "Umowa o pracę"},
        {value: "Umowa o dzieło", name: "Umowa o dzieło"},
        {value: "Umowa na zastępstwo", name: "Umowa na zastępstwo"},
        { value: "Dowolny", name: "Dowolny"}

    ];
    const poziom_stanowiska = [
        { value: "Specjalista", name: "Specjalista"},
        {value: "Inżynier", name: "Inżynier"},
        {value: "Kierownik", name: "Kierownik"},
        {value: "Manager", name: "Manager"},
        {value: "Dyrektor", name: "Dyrektor"},
        {value: "Technik", name: "Technik"},
        {value: "Dowolne", name: "Dowolne"},

    ];
  
    const miejsce_zatrudnienia = [
        { value: "Kujawsko-Pomorskie", name: "Kujawsko-Pomorskie" },
        { value: "Lubelskie", name: "Lubelskie" },
        { value: "Lubuskie", name: "Lubuskie" },
        { value: "Łódzkie", name: "Łódzkie" },
        { value: "Małopolskie", name: "Małopolskie" },
        { value: "Mazowieckie", name: "Mazowieckie" },
        { value: "Opolskie", name: "Opolskie" },
        { value: "Podkarpackie", name: "Podkarpackie" },
        { value: "Podlaskie", name: "Podlaskie" },
        { value: "Pomorskie", name: "Pomorskie" },
        { value: "Śląskie", name: "Śląskie" },
        { value: "Świętokrzyskie", name: "Świętokrzyskie" },
        { value: "Warmińsko-Mazurskie", name: "Warmińsko-Mazurskie" },
        { value: "Wielkopolskie", name: "Wielkopolskie" },
        { value: "Zachodniopomorskie", name: "Zachodniopomorskie"},
        { value: "dowolne", name: "dowolne"}
        
      ];

    const tryb_pracy = [
        {value: "stacjonarny", name: "Stacjonarna"},
        {value: "zdalny", name: "Zdalna"},
        {value: "hybrydowy", name: "Hybrydowa"},
        {value: "dowolny", name: "Dowolny"},
    ]
    const handleSposobChange = (event) => {
        const newValue = event.target.value;
        setApplyData({ ...UserApplyData, spzatrudnienia: [newValue] });
        // console.log(UserApplyData);
      };
    
      const handleMiejscechange = (event) => {
        const newValue = event.target.value;
        setApplyData({
          ...UserApplyData,
          miejsce_zatrudnienia: [newValue],
        });
        // console.log(UserApplyData);
      };
    
      const handlePoziomChange = (event) => {
        const newValue = event.target.value;
        setApplyData({ ...UserApplyData, poziom: [newValue] });
        // console.log(UserApplyData);
      };
    
      const handleDataDisplay = () => {
        // console.log(UserApplyData);
        handleZnajdywanie(UserApplyData);
      };
    
      const handleTrybChange = (event) => {
        const newValue = event.target.value;
        const isChecked = event.target.checked;
      
        setApplyData((prevData) => {
          if (isChecked) {
            // If the checkbox is checked, add the value to the list
            return { ...prevData, tryb: [...prevData.tryb, newValue] };
          } else {
            // If the checkbox is unchecked, remove the value from the list
            return { ...prevData, tryb: prevData.tryb.filter((item) => item !== newValue) };
          }
        });
      };
    useEffect(() => {
        if (isPressed) {
            handleDataDisplay();
        }
    })
    
    return (
        <div className="side_panel">
            <div className="side_panel-grid">
                <div className="grid-row">
                    <div className="opis_filtry">
                       WYBIERZ FILTRY ABYŚMY MOGLI ZNALEŹĆ NAJLEPSZĄ OFERTĘ DLA CIEBIE
                    </div>
                </div>
                <div className="grid-row">


                    <label htmlFor="miejsce_zatrudnienia">LOKALIZACJA</label>

                <div>
                    <select id="miejsce_zatrudnienia" value={UserApplyData.miejsce_zatrudnienia} onChange={handleMiejscechange} className="miejsce-lists">
                        {miejsce_zatrudnienia.map((miejsce) => (
                            <option key={miejsce.value} value={miejsce.value}>
                                {miejsce.name}
                            </option>
                        ))}
                    </select>

                </div>

                </div>
                <div className="grid-row">
                <label htmlFor="sposob_zatrudnienia">RODZAJ UMOWY</label>
                <div>
                    <select id="sposob_zatrudnienia" value={UserApplyData.spzatrudnienia} onChange={handleSposobChange} className="umowa-lists">
                        {sposob_zatrudnienia.map((sposob) => (
                            <option key={sposob.value} value={sposob.value}>
                                {sposob.name}
                            </option>
                        ))}
                    </select>
                    </div>
                </div> 
                <div className="grid-row">
                <label htmlFor="sposob_zatrudnienia">POZIOM STANOWISKA</label>
                <div>
                    <select id="poziom_stanowiska" value={UserApplyData.poziom} onChange={handlePoziomChange} className="poziom-lists">
                        {poziom_stanowiska.map((bran) => (
                            <option key={bran.value} value={bran.value}>
                                {bran.name}
                            </option>
                        ))}
                    </select>
                    </div>
                </div>
                <div className="grid-row">
                    <label htmlFor="tryb_pracy">TRYB PRACY</label>
                    <div className="tik">
                    {tryb_pracy.map((tryb) => (
                    <div
                    key={tryb.value}>
                        <input className="tik_css"
                        type="checkbox"
                        id={tryb.value}
                        name="tryb_pracy"
                        value={tryb.value}  // Add this line
                        checked={UserApplyData.tryb.includes(tryb.value)}
                        onChange={handleTrybChange}
                        />
                        <label className="tik_css_tak" htmlFor={tryb.value}>{tryb.name}</label>
                    </div>
                    ))}
                </div>
                </div>
                {!isPressed ? 
                <div className="search_container">
                    <button className="search_button" onClick={handleDataDisplay}>WYSZUKAJ</button> 
                    
                    </div>: <div></div>}
            </div>
            
        </div>
    );

}
export default Side_panel;