import React, { useState, useEffect } from "react";
import './ToFind.css';
import NavbarMain from "../Components/NavbarMain";
import axios from "axios";
import FooterBlur from "../Components/footer_blur";

function ToFind({ title, Login, isadmin}) {
  useEffect(() => {
    document.title = title || 'People Of Quality';
  }, [title]);
  // const apiurl = "http://127.0.0.1:5000";
  const apiurl = "https://peopleofqualityback.azurewebsites.net"
  const [firmaContact, setFirmaContact] = useState(['', '', '', '']);
  const [error, setError] = useState(''); // Stan dla komunikatu o błędzie

  const handleFirmaContactChange = (event, id) => {
    // console.log("Handling FirmaContact Change:", event.target.value);
    setFirmaContact(prevState => {
      const newState = [...prevState];
      newState[id] = event.target.value;
      return newState;
    });
    if (error) setError(''); // Czyści błąd przy edycji
  };

  const handleSendContact = async (e) => {
    e.preventDefault(); // Zapobiega odświeżeniu strony

    // Sprawdzenie, czy wszystkie pola są wypełnione
    if (firmaContact.some(field => field.trim() === '')) {
      setError("Proszę wypełnić wszystkie pola."); // Ustawienie komunikatu o błędzie
      return;
    }

    try {
     //  console.log("Before sending an email:", firmaContact);
      const response = await axios.post(`${apiurl}/api/SendContactEmail`, {
        'email': firmaContact[0],
        'phoneNumber': firmaContact[1],
        'name_surname': firmaContact[2],
        'company': firmaContact[3],
      });

      if (response.data.success) {
        console.log("Contact email sent successfully!");
        setError(''); // Czyści komunikat o błędzie po pomyślnym wysłaniu
      } else {
        console.error("Error sending contact email:", response.data.message);
        setError("Wystąpił błąd podczas wysyłania kontaktu."); // Ustawienie komunikatu o błędzie
      }
    } catch (error) {
      console.error("Error sending contact email:", error.message);
      setError("Wystąpił błąd podczas wysyłania kontaktu."); // Ustawienie komunikatu o błędzie
    }
  };


  return (
    <div className="ToFind">
      <NavbarMain isadmin={isadmin} />
      <div className="firm-contact-window">
      <div className="textholderko">
          <div className="h2tofind">Zostaw nam potrzebne informacje, abyśmy mogli dopasować Kandydata i skontaktować się z Tobą!</div>
        </div>
        <form className="form_tofind_container" onSubmit={handleSendContact}>
          <div className="input_tofind">
            <input
              type="email"
              placeholder="E-mail"
              value={firmaContact[0]}
              onChange={(event) => handleFirmaContactChange(event, 0)}
            />
          </div>
          <div className="input_tofind">
            <input
              type="tel"
              placeholder="Numer telefonu"
              value={firmaContact[1]}
              onChange={(event) => handleFirmaContactChange(event, 1)}
            />
          </div>
          <div className="input_tofind">
            <input
              type="text"
              placeholder="Imię i nazwisko"
              value={firmaContact[2]}
              onChange={(event) => handleFirmaContactChange(event, 2)}
            />
          </div>
          <div className="input_tofind">
            <input
              type="text"
              placeholder="Nazwa firmy"
              value={firmaContact[3]}
              onChange={(event) => handleFirmaContactChange(event, 3)}
            />
          </div>
          <button className="button3637 button38" type="submit">
            Prześlij Kontakt
          </button>
          {error && <div className="error-message">{error}</div>} {/* komunikat o wymogu uzupelnienia wszystkich pol */}
        </form>
      </div>
      <FooterBlur />
    </div>
  );
}

export default ToFind;