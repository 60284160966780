import React, { useState, useEffect } from "react";
import './LoginWindow.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import Ankieta from "../Components/Ankieta.js";
import NavbarMain from "../Components/NavbarMain";
import './ConfirmationWindow.css';
import FooterBlur from "../Components/footer_blur";
import Loading from "./loading.js";
const Login = ({ title, Login, isadmin, tryb}) => {
  const apiurl = "https://peopleofqualityback.azurewebsites.net"
  // const apiurl = "http://127.0.0.1:5000";
  // const apiurl = "https://niggeria-ai.azurewebsites.net"
  const loginEndpoint = `${apiurl}/api/loginInit`;
  const registerEndpoint = `${apiurl}/api/RegisterData`
  const [action, setAction] = useState(tryb);
  const emailRegex = /^[A-Z0-9_%+-]+(\.[A-Z0-9_%+-]+)*@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [emailError, setEmailError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [LoginError, setLoginError] = useState(false);
  const [AuthError, setAuthError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);
  const maxFileSize = 1000000;
  const [ConfirmedAnkieta, setConfirmedAnkieta] = useState(false); 
  const [showModal, setShowModal] = useState(false)
  const [showAnkieta, setShowAnkieta] = useState(false);
  const [RegistrationSuccess, setRegistrationSuccess] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isLoading, setIsLoading] =useState(false)
  const [registrationData, setRegistrationData] = useState({
    name: "",
    surename: "",
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [LoginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    document.title = title || 'People Of Quality';
    console.log(apiurl)
  }, [title]);

  const handleSubmitLoginData = () => {
    if (!emailRegex.test(LoginData.email)) {
      setEmailError(true);
      return; 
    }
    
    if (LoginData.password !== "" && LoginData.email !== "" ) {
      setIsLoading(true)
      fetch (loginEndpoint, {
        method: ['POST'],
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(LoginData),
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to upload some data");
        }
      })
      .then((data) => {
        console.log(data); // for debugging only

        if (data.message === "Login successful") {
          setIsLoading(false)
          setAuthError(false);
          const token = data.token;
          const name = data.name;
          const email = data.email;
          const woj = data.woj;
          const lastName = data.lastName;
          //console.log(email)
          //console.log(woj)
          //console.log(name)
          //console.log(lastName)

          localStorage.setItem('token', token);
          localStorage.setItem('name', name);
          localStorage.setItem('email', email);
          localStorage.setItem('woj', woj);
          localStorage.setItem('lastName', lastName);
          
          window.location.href = '/Oferty';
        } else {
          setIsLoading(false);
          setAuthError(true);
          // login failed, need to add some sort od message that will be displayed
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error);
        setAuthError(true);
      });
    }
    
  };
  const copy_user_no_ankieta = (email) => {
    console.log(email);
    axios.post(`${apiurl}/api/copyAnkieta`, {"email": email}, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(response => {
        if (response.status === 200) {
            console.log("Copy ankieta successful");
            window.location.href = '/Oferty';
        } else {
            console.log("Copy ankieta failed with status: ", response.status);
        }
    })
    .catch(error => {
        console.error("Error in copy_user_no_ankieta:", error);
    });
}
  const handleSubmitRegisterData = () => {
    
    if (!emailRegex.test(registrationData.email)) {
      setEmailError(true);
      return;
    }
    if (registrationData.password !== registrationData.repeatPassword) {
      setPasswordMatchError(true);
      return;
    }
    if (registrationData.password !== "" && registrationData.email !== "" && registrationData.repeatPassword !== "" && registrationData.name !== "") {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("name", registrationData.name);
      formData.append("lastName", registrationData.surename);
      formData.append("email", registrationData.email);
      formData.append("password", registrationData.password);
      formData.append("repeatPassword", registrationData.repeatPassword);
      formData.append("cv_file", selectedFile);
      axios.post(registerEndpoint, formData)
        .then((response) => {
          // console.log(response.data);
          if (response.data.message === "User registered successfully") {
            console.log("User registered successfully");
            const token = response.data.token;
            const name = response.data.name; 
            const email = response.data.email;
            const filename = response.data.filename;
            const lastName = response.data.lastName;
            const nr_tel = response.data.nr_tel;
            const woj = response.data.woj;
            const liked = response.data.liked
            localStorage.setItem('token', token);
            localStorage.setItem('name', name);
            localStorage.setItem("email", email);
            localStorage.setItem("filename", filename);
            localStorage.setItem("lastName", lastName);
            localStorage.setItem("nr_tel", nr_tel);
            localStorage.setItem("woj", woj);
            localStorage.setItem("liked", liked);
            setIsLoading(false)
            setSelectedFile(null);
            setSelectedFileName("");
            setShowAnkieta(true);
            setShowModal(true);
          
          } else {
            setIsLoading(false)
            console.log("Problem podczas rejestracji");
          }
        })
        .catch((error) => {
          setIsLoading(false)
          console.log("Problem podczas rejestracji");
          console.error(error);
        });
    }
  };
  const handle_ankieta_response = (value) => {
    const email = localStorage.getItem("email")
    if (value === true) {
      setRegistrationSuccess(true);
      window.location.href = '/Ankieta';
    } else if (value === false) {
      setRegistrationSuccess(true);
      copy_user_no_ankieta(email)
    }
  }

  const handle_file_change = (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = ["pdf", "doc", "docx"];
    const fileExtension = file && file.name ? file.name.split(".").at(-1) : null;

    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      return false;
    }
    if (file.size > maxFileSize) {
      window.alert(`File size too big. Files must be less than 1 MB`);
      return false;
    }
    // console.log(fileExtension);
    setSelectedFile(file);
    setSelectedFileName(file.name);
  }
  const closeModal = () => {
    setShowModal(false);
  };
  const resetPassword = () => {
    window.location.href = '/ZmianaHasła'
  }
  const SendEmptyAnkieta = () => {
    try {
      const response = axios.get(`${apiurl}/api/postAnkieta`, {"email": registrationData.email})
      if (response.data.message === "User's questionnaire updated successfully") {
        // console.log("")
    }
  } catch(error) {
    console.error("Error getting offers", error);
  }
  }
  const handleChangetryb = (cel) => {
    window.location.href = `/${cel}`
  }

  return (
    <div className="login_page">
      <NavbarMain isadmin = {isadmin}/>
      {isLoading ? <Loading /> : <></>}
      <div className="container">
        <div className="header">
          <div className="SignOrLogin">{action === "Login" ? "Zaloguj się" : "Zarejestruj się"}</div>
          <div className="underline"></div>
        </div>
        <div className="inputs">
          {action === "Login" ? <div></div> : 
            <div className="input_login">
              <input 
                type="text" 
                placeholder="Imię" 
                value={registrationData.name} 
                onChange={(e) => 
                  setRegistrationData({
                    ...registrationData,
                    name: e.target.value,
                  })
                } 
              />

            </div>
            
          }
          {action === "Login" ? <div></div> : 
            <div className="input_login">
              <input 
                type="text" 
                placeholder="Nazwisko" 
                value={registrationData.surename} 
                onChange={(e) => 
                  setRegistrationData({
                    ...registrationData,
                    surename: e.target.value,
                  })
                } 
              />
              
            </div>     
          }
          <div className="input_login">
            <input
              type="email"
              placeholder="E-mail"
              value={action === "Login" ? LoginData.email : registrationData.email}
              onChange={(e) => {
                if (action === "Login") {
                  setLoginData({
                    ...LoginData,
                    email: e.target.value,
                  });
                  setEmailError(false);
                  setAuthError(false);
                } else if (action === "Sign Up") {
                  setRegistrationData({
                    ...registrationData,
                    email: e.target.value,
                  });
                  setEmailError(false);
                  setAuthError(false);
                }
              }}
            />
          </div>
          {emailError && <div className="error-message">Niewłaściwy e-mail</div>}
          <div className="input_login">
            <input 
              placeholder="Hasło" 
              type="password"
              value={action === "Login" ? LoginData.password : registrationData.password} 
              onChange={(e) => {
                if (action === "Login") {
                  setLoginData({
                    ...LoginData,
                    password: e.target.value,
                  });
                } else if (action === "Sign Up") {
                  setRegistrationData({
                    ...registrationData,
                    password: e.target.value,
                  });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  action === "Login" ? handleSubmitLoginData() : handleSubmitRegisterData();
                }}} 
            />
          </div>
          {action === "Sign Up" && (
            <div className="input_login">
              <input
                placeholder="Powtórz hasło"
                type="password"
                onChange={(e) => {
                  if (action === "Sign Up") {
                    setRegistrationData({
                      ...registrationData,
                      repeatPassword: e.target.value,
                    });
                    setPasswordMatchError(false);
                  }
                }}
              />
            </div>
          )}
          {action === "Sign Up" && (
            <div>
              <div className="submit_row">
              <label className="submit_file" htmlFor="cvFileInput"></label>
              <input type="file" id="cvFileInput" className="submit_file_cv" onChange={handle_file_change} />
              <div className="selected-filename">
                {selectedFileName}
              </div>
              </div>
              </div>
          )}
          {passwordMatchError && <div className="error-message">Hasła nie są identyczne</div>}
          {action === "Sign Up" ? <div className="forgot-password">Klikając "Zarejestruj się" zgadzasz się na <Link to="/Warunki"><span>Politykę prywatności</span></Link> i <Link to="/Regulamin"><span>regulamin Strony</span></Link> </div> : 
            <div className="forgot-password">
           Zapomniałeś/aś hasła? <Link to="/Forgot"><span>Kliknij tutaj!</span></Link>
            </div>
          }
          <div className="submit_row">
              <button className={action === "Login" ? "submit" : "submit"} onClick={() => {
                if (action === "Login") {
                  handleSubmitLoginData();
                } else if (action === "Sign Up") {
                  handleSubmitRegisterData();

                  console.log(showAnkieta);
                  //handle_file_upload();
                
                }
              }}>
                {action === "Login" ? "Zaloguj się" : "Zarejestruj się"}
              </button>
              {LoginError && <div className="error-message">Wypełnij cały formularz</div>}
              {AuthError && <div className="error-message">E-mail lub hasło jest niepoprawne</div>}
              {action === "Sign Up" && showAnkieta === true && (     
              <div className="modal" id="modal">
                    <div className="modal-body">
                        <div className="modal-header">
                          <div className="title">Dziekujemy za rejestrację</div>
                            <button data-close-button className="close-button">&times;</button>
                          </div>
                          <div className="modal-body">Czy chciałbyś wypełnić ankietę, abyśmy mogli lepiej zrozumieć Twoje umiejętności i dopasować oferty do Ciebie?
                          <div className="submit-row-ankieta">
                            <button className="button-questionnaire" onClick={() => {handle_ankieta_response(true)}}>Kliknij tutaj</button>
                            <button className="button-questionnaire" onClick={() => {handle_ankieta_response(false)}}>Nie, dziękuję</button> 
                          </div>
                        </div>
                      </div>
                    </div>

              )}

          </div>
            <div className="submit-container">
              {action === "Login" && (
                <div className="submit-row">
                  <button className="submit-gray gray" onClick={() => { handleChangetryb("Rejestracja") }}>
                    Nie masz konta? <br /> Zarejestruj się
                  </button>
                </div>
              )}
              {action === "Sign Up" && (
                <div className="submit-row">
                  <button className="submit-gray gray" onClick={() => { handleChangetryb("Login") }}>Masz już konto? <br /> Zaloguj się</button>
                </div>
              )}
            </div>
            
                    </div>
                    
                  </div>
                  <footer className={`git ${isFooterVisible ? 'visible' : ''}`}>
      <p>Copyright © 2023 peopleofquality.pl - All Rights Reserved.</p>
      <p className="polityka-link"><Link to="/Warunki"><span>Polityka prywatności</span><br /><br /></Link><Link to="/Regulamin"><span> Regulamin strony</span></Link> </p>
    </footer>
                </div>
  );
}

export default Login;