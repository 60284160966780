import React, {useState, useEffect} from "react";
import axios from "axios";
import BestOffer from "../Components/bestoffer";
import NavbarMain from "../Components/NavbarMain";
import "./ListaBest.css"
function Best( {title, isadmin}) {
    const apiurl = "https://peopleofqualityback.azurewebsites.net"
    //const apiurl = "http://127.0.0.1:5000"
    useEffect(() => {
        document.title = title || 'People Of Quality';
        getBestNVerified();
        getBestVerified();
        getOffers();
      }, [title]);
             const [allOffers, setAllOffers] = useState([]);



    const getOffers = async () => {
        try {
            const response = await axios.post(`${apiurl}/api/AllOffers`, {"title": title});

            if (response.data.message === "got details") {
                setAllOffers(response.data.data)
                console.log(response.data.data);
            }
        } catch (error) {
            console.error("Error getting offers", error);
        }
    };
    const getBestVerified = async () => {
        try {
            const response = await axios.post(`${apiurl}/api/lista-best`);

            if (response.data.message === "got details") {
                setAllOffers(response.data.data)
                console.log(response.data.data);
            }
        } catch (error) {
            console.error("Error getting offers", error);
        }
    };
    const getBestNVerified = async () => {
        try {
            const response = await axios.post(`${apiurl}/api/lista-best-nverified`);

            if (response.data.message === "got details") {
                setAllOffers(response.data.data)
                console.log(response.data.data);
            }
        } catch (error) {
            console.error("Error getting offers", error);
        }
    };


        return (
            <div>
                <NavbarMain isadmin = {isadmin}/>
                <div className="oferta_best">    
                {allOffers.map((offer) => (
                    <BestOffer
                    key={offer._id}
                    firma={offer.Firma || ''}
                    wojewodztwo={(offer.Wojewodztwa || []).join(", ")}
                    stanowisko={(offer.Pozycja || []).join(", ")}
                    best={Array.isArray(offer.Best) ? offer.Best : [offer.Best]}
                    NotVer={Array.isArray(offer.NotVer) ? offer.NotVer : [offer.NotVer]}
                    id_={offer._id}
                    visible={true}
                />
                ))}
                </div>
            </div>
        )

}

export default Best;