import React, { useEffect, useState } from "react";
import NavbarMain from "../Components/NavbarMain";
import axios from "axios";
import Users from "../Components/Users";
import "./ListUzytkownikow.css"
import "../Components/Users.css"

function ListaUzytkownikow({ title, isadmin}){
    //const apiurl = "http://127.0.0.1:5000" 
    const apiurl = "https://peopleofqualityback.azurewebsites.net"   
    const [users, setUsers] = useState([]);
    const [notusers, setNUsers] = useState([])
    const [verified, setVerified] = useState([])

    useEffect(() => {
        document.title = title || 'People Of Quality';
        getUsers();
        getNUsers();
        getVerified();
      }, [title]);

      const getUsers = async () => {
        try {

            const response = await axios.post(`${apiurl}/api/Alls`);

            if (response.data.message === "got users") {
                // console.log(response.data.data);
                setUsers(response.data.data);
            }

        } catch (error) {
            console.error("Error getting offers", error);
        }
      };
      const getNUsers = async () => {
        try {

            const response = await axios.post(`${apiurl}/api/AllsNot`);

            if (response.data.message === "got users") {
                // console.log(response.data.data);
                setNUsers(response.data.data);
            }

        } catch (error) {
            console.error("Error getting offers", error);
        }
      };
      const getVerified = async () => {
        try {

          const response = await axios.post(`${apiurl}/api/AllVerified`);

          if (response.data.message === "got users") {
              // console.log(response.data.data);
              setVerified(response.data.data);
          }

      } catch (error) {
          console.error("Error getting offers", error);
      }
      };
    return (
        <div>
          <NavbarMain isadmin = {isadmin}/>
          <div className="listaU">
          <div className="oferta_box">
            <h2>Zweryfikowani użytkownicy</h2>    
              {verified.map((offer) => (
                <div><Users 
                key={offer._id.$oid}
                email={offer.email}
                imie={offer.name}
                nazwisko= {offer.lastName}
                ankietaResponses={offer.ankietaResponses}
                id_={offer._id}
                date = {offer.ModDate}
                AdminMail = {offer.ModMail}
                Filename = {offer.filename}
                visible={true}
                /></div>
              ))}
            </div>
            <div className="oferta_box">
            <h2>Użytkownicy z ankietami</h2>    
              {users.map((offer) => (
                <div><Users 
                key={offer._id.$oid}
                email={offer.email}
                imie={offer.name}
                nazwisko= {offer.lastName}
                ankietaResponses={offer.ankietaResponses}
                id_={offer._id}
                date = {offer.ModDate}
                AdminMail = {offer.ModMail}
                Filename = {offer.filename}
                visible={true}
                /></div>
              ))}
            </div>
            <div className="oferta_box" style={{"width": 40 + "%"}}>
            <h2>Użytkownicy bez ankiety</h2>    
              {notusers.map((offer) => (
                <div><Users 
                key={offer._id.$oid}
                email={offer.email}
                imie={offer.name}
                nazwisko= {offer.lastName}
                Filename = {offer.filename}
                ankietaResponses={offer.ankietaResponses}
                id_={offer._id}
                date = {''}
                AdminMail = {''}
                visible={true}
                /></div>
              ))}
            </div>
            </div>
        </div>
    )
}


export default ListaUzytkownikow;