import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import "./warunki.css"
import NavbarMain from "../Components/NavbarMain";
function Warunki({title, isadmin, Login}) {
    useEffect(() => {
        document.title = title || 'People Of Quality';
        

      }, [title]);


      return (
        <div>
          <NavbarMain isadmin = {isadmin}/>
          <div className="warunki_css_"> 
            <h1 className="warunki_css_h1">Polityka prywatności</h1>
            <div>
              <p>
              Niniejsza Polityka stanowi podstawową informację o celach, sposobach przetwarzania oraz
bezpieczeństwie Twoich danych osobowych, jako Użytkownika strony: www.peopleofquality.pl
(zwanej dalej Stroną). Zapoznając się z Polityką prywatności dowiesz się kto jest
administratorem Twoich danych osobowych, jakie dane osobowe są przez Stronę zbierane, w
jakich celach są wykorzystywane oraz jak są chronione.
              </p>
              <p>
              §1. Informacje podstawowe.
              </p>
              <p>
              1. Administratorem Twoich danych osobowych jest: PEOPLE OF QUALITY KUŚPAK I
STALA spółka jawna z siedzibą w Kryspinowie 32-060 ul. Zaolsze 20, NIP: 944-22-86-713,
KRS: 0001084610
2. Kontakt z Administratorem jest możliwy za pośrednictwem:
1) poczty e-mail: biuro@peopleofquality.pl
              </p>
              <p>
              §2. Zasady przetwarzania danych.
              </p>
              <p>
              1. Administrator przetwarza dane osobowe z poszanowaniem następujących zasad:<br />
              2) rzetelnie i uczciwie (rzetelność);<br />
              1) w oparciu o podstawę prawną i zgodnie z prawem (legalizm)<br />
              2) rzetelnie i uczciwie (rzetelność);<br />
              3) w sposób przejrzysty dla osoby, której dane dotyczą (transparentność);<br />
              4) w konkretnych celach i nie „na zapas” (minimalizacja);<br />
              5) nie więcej niż potrzeba (adekwatność);<br />
              6) z dbałością o prawidłowość danych (prawidłowość);<br />
              7) nie dłużej niż potrzeba (czasowość);<br />
              8) zapewniając odpowiednie bezpieczeństwo danych (bezpieczeństwo).<br />
              </p>
              <p>
              §3. Cele, podstawy prawne i zakres przetwarzania danych.
              </p>
              <p>
              1. Twoje dane będziemy przetwarzać:<br />
1) w celach rekrutacyjnych, aby zweryfikować Twoje umiejętności, a tym samym możliwie
najlepiej dopasować Cię do konkretnych ofert pracy.<br />
2) w celach kontaktowych, aby umówić się z Tobą na spotkanie rekrutacyjne online, jak
również na rozmowę kwalifikującą z naszymi Klientami.<br />
3) w celach kontaktowych, aby udzielić ci odpowiedzi na Twoją wiadomość przesłaną nam
bezpośrednio drogą e-mailową lub za pośrednictwem formularza na podstawie realizacji

prawnie uzasadnionego interesu Administratora związanego z koniecznością udzielenia
Ci odpowiedzi.<br />
4) w celach technicznych przy użyciu plików cookies (ciasteczka techniczne), na podstawie
realizacji prawnie uzasadnionego interesu Administratora związanego z prawidłowym
działaniem i funkcjonowaniem Strony.<br />
5) w celach statystycznych i analitycznych, jeśli wyraziłeś zgodę na wykorzystanie przez
nas plików cookies do celów analitycznych (ciasteczka analityczne).<br />
6) w celach związanych z prowadzeniem przez nas profilu firmowego w mediach
społecznościowych (takich jak Facebook / Instagram / Linkedin) na zasadach
określonych przez właścicieli danego medium (portalu) i informowania w nim o naszych
produktach, promocjach i innych wiadomościach dotyczących naszej działalności, co
uważamy za nasz prawnie uzasadniony interes,<br />
              </p>
              <p>
              2. Przetwarzamy Twoje dane w zakresie:<br />
1) Podstawowe dane identyfikacyjne i kontaktowe tj. imię i nazwisko, adres email,
preferowane województwo do poszukiwania pracy oraz wszystkie dane zawarte w CV,
które Użytkownik dobrowolnie udostępnia na stronie. W każdym czasie możesz się do
nas zwrócić z prośbą o usunięcie tych danych.<br />
2) Dodatkowe informacje, które Użytkownik zdecyduje nam się przekazać w wiadomości
email, podczas rozmowy weryfikującej jak również podczas rozmowy kwalifikacyjnej z
Klientem.<br />
3) jeśli prześlesz nam wiadomość e-mail to będziemy przetwarzać dane, które będą w niej
zawarte. Przekazanie nam danych w e-mailu następuje dobrowolnie. W każdym czasie
możesz zwrócić się do nas z prośbą o usunięcie tych danych.<br />
4) jeśli skorzystać z formularza kontaktowego na naszej stronie to będziemy przetwarzać
dane w postaci twojego adresu e-mail oraz imienia. Podanie tych danych stanowi
warunek udzielenia przez nas odpowiedzi na Twoje zapytanie.<br />
5) pozostałe dane, które Administrator przetwarza to adres IP i inne dane zapisywane w
plikach cookies. To Ty decydujesz w jakim zakresie będziemy mogli wykorzystać pliki
cookies z Twoimi danymi. Przekazanie nam danych w ten sposób następuje
dobrowolnie. W każdym czasie możesz zmienić swoje preferencje. Możesz to zrobić w
swojej przeglądarce internetowej lub też po usunięciu zapisanych plików cookies
pochodzących z naszej Strony.<br />
6) jeśli polubisz lub w inny sposób dołączysz do naszego profilu w mediach
społecznościowych (np. Facebook) będziemy przetwarzać Twoje dane, które podałeś w
danym medium i do których będziemy mieli dostęp. W celu zakończenia przetwarzania
możesz zakończyć subskrypcję naszego profilu.<br />
7) API zewnętrznych partnerów: Nasza strona może korzystać z API (interfejsów
programistycznych aplikacji) dostarczanych przez zewnętrznych partnerów w celu
zintegrowania różnych funkcjonalności, takich jak mapy, usługi analityczne, reklamoweczy media społecznościowe. Korzystanie z tych API może prowadzić do tworzenia
dodatkowych ciasteczek lub korzystania z istniejących ciasteczek w celu przetwarzania
danych użytkowników. Zewnętrzni partnerzy mogą przechowywać i przetwarzać te dane
zgodnie z własnymi politykami prywatności.<br />
              </p>
              <p>
              §4. Odbiorcy danych oraz zamiar przekazywania danych do Państwa spoza EOG lub organizacji międzynarodowej.
              </p>
              <p>
              1. Odbiorcami Twoich danych osobowych są:<br />
1) jeśli wyraziłeś zgodę na ciasteczka marketingowe, Twoje dane zapisane w plikach
cookies, będą przekazane do naszych partnerów reklamowych.<br />
2) jeśli wyraziłeś zgodę na ciasteczka analityczne, Twoje dane zapisane w plikach cookies,
będą przetwarzane przez podmiot dostarczający oprogramowanie do analizy ruchu
naszej stronie (np. Google Analytics)<br />
3) Twoje dane nie są przekazywane do Państw spoza EOG lub organizacji
międzynarodowych, z wyjątkiem partnerów wymienionych poniżej:<br />
              </p>
              <p>
              1) Google Inc. z siedzibą w USA w zakresie analityki ruchu na stronie w systemie Google
Analytics oraz dostarczania dodatkowych funkcji (takich jak Google Maps, Google Search
Console) związanych z prawidłowym funkcjonowaniem strony oraz wyświetlaniem wyników
wyszukiwania.<a href="https://policies.google.com/privacy?hl=pl"> Zobacz jak Google przetwarza i chroni Twoje dane </a>. <a href="https://tools.google.com/dlpage/gaoptout/">Ten dodatek do przeglądarki
umożliwi Ci zarządzanie zakresem i poziomem dostępu do danych zbieranych przez Google</a>
Analytics.
              </p>
              <p>
              2) Google Inc. z siedzibą w USA w zakresie reklam i mierzenia ich skuteczności w systemie
Google Ads. <a href="https://policies.google.com/privacy?hl=pl"> Zobacz jak Google przetwarza i chroni Twoje dane.</a> <a href="https://myadcenter.google.com/customize?hl=pl"> Tutaj możesz zarządzać swoimi
preferencjami i informacjami w systemie reklamowym Google.</a>
              </p>
              <p>
              3) Facebook Inc. z siedzibą w USA w zakresie obsługi konta i dostępu do danych w serwisach
społecznościowych (Facebook, Instagram), reklam i mierzenia ich skuteczności związanych z
instalacją na stronie piksela Facebooka oraz API Konwersji. Zobacz jak<a href="https://www.facebook.com/business/gdpr">Facebook przetwarza i
chroni Twoje dane.</a>

              </p>
              <p>
              §5. Termin przechowywania danych.
              </p>
              <p>
              Nie jesteśmy w stanie ustalić przez jaki okres przetwarzać będziemy Twoje dane osobowe. Jeśli
przekazałeś nam swoje dane w formularzu lub wiadomości e-mail w celach kontaktowych to
Twoje dane będziemy przetwarzać do momentu złożenia przez Ciebie żądania usunięcia tych
danych lub sprzeciwu wobec przetwarzania, ale również w przypadku, w którym uznamy, że
zrealizowaliśmy prawnie uzasadniony interes Administratora.
              </p>
              <p>
              §6. Prawa użytkowników. 
              </p>
              <p>
              1. W związku z przetwarzaniem Twoich danych przysługują Ci następujące prawa:<br />
1) dostępu do treści swoich danych oraz<br />
2) prawo ich sprostowania, usunięcia, ograniczenia przetwarzania,<br />
3) prawo do przenoszenia danych,<br />
4) prawo wniesienia sprzeciwu,<br />
5) prawo do cofnięcia zgody na ich przetwarzanie w dowolnym momencie i w dowolnej
formie, chyba że przetwarzanie Państwa danych odbywa się w celu wykonywania
umowy przez Administratora, w celu wywiązania się przez Administratora z
obowiązków prawnych względem instytucji państwowych lub w celu realizacji prawnie
uzasadnionych interesów Administratora.
2. Masz także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (na
adres Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa).
3. Więcej informacji w przedmiocie ochrony danych osobowych mogą Państwo otrzymać na
stronie internetowej Urzędu Ochrony Danych Osobowych: <a href="http://www.uodo.gov.pl">www.uodo.gov.pl</a>
<br />
              </p>
              <p>
              §7 Informacja o ciasteczkach
              </p>
              <p>
              1. Na naszej stronie wykorzystujemy ciasteczka (pliki cookies).<br />
2. Ciasteczka (pliki cookies) są niewielkimi plikami tekstowymi przechowywanymi na
urządzeniach użytkowników podczas przeglądania strony internetowej. Są one
wykorzystywane w celu ułatwienia korzystania z naszej strony, poprawienia jakości
usług oraz umożliwienia analizy ruchu i zachowań użytkowników.<br />
3. Ciasteczka mogą być stałe (trwałe) lub tymczasowe (sesyjne). Stałe ciasteczka są
przechowywane na urządzeniu użytkownika przez określony czas lub do momentu ich
usunięcia przez użytkownika. Tymczasowe ciasteczka są usuwane po zamknięciu
przeglądarki.<br />
4. Wykorzystujemy ciasteczka własne oraz ciasteczka stron trzecich. Ciasteczka własne są
wykorzystywane w celu zapewnienia prawidłowego działania strony, personalizacji
treści, zapisywania preferencji użytkowników oraz analizy statystyk. Ciasteczka stron
trzecich są wykorzystywane przez zewnętrzne narzędzia analityczne, reklamowe oraz do
integracji z mediami społecznościowymi.<br />
5. Użytkownicy mają prawo kontrolować i ograniczać sposób, w jaki ciasteczka są
wykorzystywane na naszej stronie. Większość przeglądarek internetowych umożliwia
zarządzanie plikami cookies, w tym blokowanie, ograniczanie lub usuwanie ciasteczek.
Szczegółowe informacje na temat zarządzania ciasteczkami można znaleźć w
ustawieniach przeglądarki użytkownika.<br />
6. Należy pamiętać, że wyłączenie lub ograniczenie ciasteczek może wpłynąć na
funkcjonalność strony oraz jakość świadczonych usług.<br />
7. Na naszej stronie wykorzystujemy różne rodzaje ciasteczek, aby lepiej dostosować się do
potrzeb użytkowników i zapewnić optymalne korzystanie ze strony.<br />
8. Ciasteczka techniczne: Są niezbędne do prawidłowego funkcjonowania witryny.
Umożliwiają utrzymanie sesji użytkownika, obsługę formularzy, zapamiętywanie
preferencji oraz zapewnienie bezpieczeństwa strony.<br />
9. Ciasteczka marketingowe: Pozwalają na personalizację reklam oraz prowadzenie działań
remarketingowych, które są dostosowane do zainteresowań i preferencji użytkowników.
Służą również do mierzenia skuteczności kampanii reklamowych oraz optymalizacji
treści reklamowych.<br />
10. Ciasteczka analityczne: Są wykorzystywane do zbierania informacji o sposobie
korzystania z witryny przez użytkowników, takich jak liczba odwiedzin, strony
odwiedzane, czas spędzony na stronie czy źródło ruchu. Uzyskane dane pomagają w
analizie i optymalizacji strony, poprawiając jej funkcjonowanie i dostosowując treści do
potrzeb użytkowników.<br />
              </p>
              <p>
              §8 Dlaczego korzystamy z ciasteczek?
              </p>
              <p>
              1. Komfort korzystania ze strony: Ciasteczka (pliki cookies) mogą znacząco wpłynąć na
komfort korzystania ze strony internetowej.<br />
2. Personalizacja treści: Ciasteczka pozwalają na dostosowanie wyświetlanych treści do
preferencji i zainteresowań użytkownika, co sprawia, że strona staje się bardziej
atrakcyjna i użyteczna.<br />
3. Zapamiętywanie ustawień: Dzięki ciasteczkom strona może zapamiętać wybrane przez
użytkownika ustawienia, takie jak język, układ czy kolorystyka, co pozwala na szybsze i
wygodniejsze korzystanie z serwisu.<br />
4. Logowanie: Ciasteczka umożliwiają przechowywanie informacji o zalogowanym
użytkowniku, co pozwala na łatwe przełączanie się między różnymi sekcjami strony bez
konieczności ponownego logowania.<br />
5. Utrzymanie sesji: Dzięki ciasteczkom strona może śledzić aktywność użytkownika na
różnych podstronach, co pozwala na łatwe wracanie do ostatnio odwiedzanych sekcji lub
kontynuowanie niedokończonych działań, takich jak wypełnianie formularzy.<br />
6. Optymalizacja wydajności: Ciasteczka mogą być wykorzystane do analizy ruchu i
zachowań użytkowników na stronie, co pomaga w optymalizacji jej wydajności oraz
dostarczaniu treści o wysokiej jakości.<br />
7. Reklamy: Ciasteczka umożliwiają wyświetlanie spersonalizowanych reklam, które są
dopasowane do zainteresowań i potrzeb użytkownika. Dzięki temu, reklamy są mniej
inwazyjne i bardziej adekwatne.<br />
8. Integracja z mediami społecznościowymi: Ciasteczka pozwalają na integrację z
serwisami społecznościowymi, takimi jak Facebook, Twitter czy Instagram, co
umożliwia łatwe udostępnianie treści oraz korzystanie z dodatkowych funkcji, takich jak
komentowanie czy polubienia.<br />
              </p>
            <p>
            §9 Funkcje ciasteczek technicznych
            </p>
            <p>
            1. Utrzymanie sesji użytkownika: Ciasteczka techniczne umożliwiają utrzymanie sesji
użytkownika podczas poruszania się po stronie, co pozwala na przechodzenie między
różnymi sekcjami witryny bez konieczności ponownego logowania czy wprowadzania
tych samych informacji.<br />
2. Zapamiętywanie preferencji użytkownika: Dzięki ciasteczkom technicznym strona może
przechowywać informacje na temat preferencji użytkowników, takie jak wybrany język,
wielkość czcionki, ustawienia kolorystyczne czy inne elementy personalizacji.<br />
3. Obsługa formularzy: Ciasteczka techniczne wspomagają funkcjonowanie formularzy na
stronie, takich jak formularze logowania, rejestracji czy kontaktowe, zapewniając
prawidłowe przetwarzanie danych wprowadzanych przez użytkowników.<br />
4. Optymalizacja wydajności strony: Użycie ciasteczek technicznych umożliwia
monitorowanie wydajności strony oraz szybkość ładowania poszczególnych elementów,
co pozwala na optymalizację i poprawę jakości świadczonych usług.<br />
5. Bezpieczeństwo: Ciasteczka techniczne pomagają w zapewnieniu bezpieczeństwa
witryny i jej użytkowników, na przykład poprzez weryfikację tożsamości użytkowników,
wykrywanie prób włamań czy ochronę przed atakami typu CSRF (Cross-Site Request
Forgery).<br />
            </p>
<p>
§10 Funkcje ciasteczek analitycznych
</p>
<p>
1. Pomiar ruchu na stronie: Ciasteczka analityczne pozwalają na monitorowanie liczby
odwiedzin, unikalnych użytkowników i wyświetleń stron, co daje ogólny obraz
popularności i wydajności witryny.<br />
2. Analiza zachowań użytkowników: Dzięki ciasteczkom analitycznym można zbierać
informacje o sposobie, w jaki użytkownicy poruszają się po stronie, na przykład czas
spędzony na poszczególnych podstronach, ścieżki nawigacji oraz miejsca, w których
opuszczają witrynę.<br />
3. Optymalizacja konwersji: Ciasteczka analityczne pomagają w identyfikowaniu
elementów strony, które wpływają na skuteczność osiągania celów biznesowych, takich
jak rejestracja, złożenie zamówienia czy wypełnienie formularza kontaktowego.<br />
4. Segmentacja użytkowników: Użycie ciasteczek analitycznych umożliwia grupowanie
użytkowników według różnych kryteriów co pozwala na lepsze dostosowanie treści i
oferty do potrzeb poszczególnych segmentów.<br />
5. Testowanie A/B: Ciasteczka analityczne są wykorzystywane do przeprowadzania testów
A/B, które polegają na porównaniu dwóch wersji strony internetowej, aby sprawdzić,
która z nich lepiej spełnia założone cele. Testy te pomagają w optymalizacji wyglądu,
funkcjonalności i treści witryny.<br />
</p>

            </div>
            </div>
        </div>
      );
}

export default Warunki;