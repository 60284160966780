import React, { useState } from "react";
import "./Users.css"
import axios from "axios";
import { fireEvent } from "@testing-library/react";
import Loading from "../pages/loading";
const questions = [
  "ControlPlan",
  "FlowChart",
  "QRQC",
  "PPAP",
  "8D",
  "FMEA",
  "FTA",
  "SPC",
  "MSA",
  "A3",
  "Pareto",
  "Ishikawa",
  "5Why",
  "PPM & TLR",
  "P5W2H",
  "PDCA",
  "FPD",
  "IATF",
  "RunAtRate",
];

function Users({ email, imie, nazwisko, ankietaResponses, id_, date, AdminMail, Filename}, visible) {
  const apiurl = "https://peopleofqualityback.azurewebsites.net";
  //const apiurl = "http://127.0.0.1:5000"
  const [dropped, setDropped] = useState(false);
  const [ratings, setRatings] = useState(ankietaResponses || {});
  const [loading, setLoading] = useState(false);
  const handleDropdownToggle = () => {
    // setDropped(!dropped);
  };

  const handleRatingChange = (question, newRating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [question]: newRating,
    }));
  };

  const handleSaveRatings = () => {

    const backendURL = `${apiurl}/api/save-ratings`;
    setLoading(true)
    const ModMail = localStorage.getItem("email")
    axios
      .post(backendURL, {
        userId: email,
        ratings: Object.fromEntries(Object.entries(ratings).map(([key, value]) => [key, value.toString()])),
        Mod: ModMail,

      })
      .then((response) => {
        console.log("Ratings saved successfully:", response.data);
        setLoading(false)
        // You can perform additional actions after saving the ratings if needed
      })
      .catch((error) => {
        console.error("Error saving ratings:", error);
        setLoading(false)
      });
  };
  const handleDeleteUser = () => {
    const backendURL = `${apiurl}/api/delete`;
    axios
      .post(backendURL, {
        email: email,
      })
      .then((response) => {
        console.log("User deleted:", response.data);
        setLoading(false)
        
      })
      .catch((error) => {
        console.error("Error saving ratings:", error);
        setLoading(false)
      });
  }

  const handleCVDownload = () => {
    setLoading(true)
    const backendURL = `${apiurl}/api/DownloadCV?email=${email}`;
    axios
      .get(backendURL, {
        responseType: 'blob' 
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        
        // Extracting filename from response headers if the header is present
        const contentDispositionHeader = response.headers['content-disposition'];
        if (contentDispositionHeader) {
          const filename = `${imie}_${nazwisko}_CV.pdf`;
          link.setAttribute('download', filename); 
        } else {
          // If the content-disposition header is not present, set a default filename
          const filename = `${imie}_${nazwisko}_CV.pdf`;
          link.setAttribute('download', filename); 
        }
        
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading CV:", error);
        setLoading(false)
      });
      setLoading(false)
  };
  const offerBoxClass = `OfferBoxWelcome ${dropped ? "tak" : "nie"}`;

  return (
    <div className={offerBoxClass}>
      {loading ? <Loading /> : <></>}
      <div onClick={handleDropdownToggle}>
        {visible ? (
          <div>
            <li>Email: {email}</li>
            <li>Imię: {imie}</li>
            <li>Nazwisko: {nazwisko}</li>
            <li>Załadowane CV: {Filename}</li>
            <li>Ostatnio zmodyfikowano {date} przez {AdminMail}</li>
             <h2>Odpowiedzi z ankiety:</h2>
              {ankietaResponses && Object.keys(ankietaResponses).length > 0 && (
                <div>
                  
                  <ul>
                    {questions.map((question, index) => (
                      <li key={index}>
                        {question}:{" "}
                        <select
                          value={ratings[question] || 0}
                          onChange={(e) =>
                            handleRatingChange(question, parseInt(e.target.value))
                          }
                        >
                          {[0,1, 2, 3, 4, 5].map((rating) => (
                            <option key={rating} value={rating}>
                              {Array.from({ length: rating }, (_, i) => "★").join("")}
                            </option>
                          ))}
                        </select>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            
            <button onClick={handleSaveRatings}>Zapisz zmiany</button>
            <button onClick={handleDeleteUser}>Usuń użytkownika</button>
            <button onClick={handleCVDownload}>Pobierz CV</button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default Users;
