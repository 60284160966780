import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./Onas.css"
import firmalogo from "../Components/images/pq-logo.png"
import kevin_photo from "../Components/images/kevin_photo.webp"
import aleksander_photo from "../Components/images/aleksander_photo.webp"
import NavbarMain from "../Components/NavbarMain";


function ONas( {title, Login, isadmin  }) {
  const [showContent, setShowContent] = useState(false);
  const [showH1, setShowH1] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  
  useEffect(() => {
    document.title = title || 'People Of Quality';

    const delayTimer = setTimeout(() => {
      setShowH1(true);
      const delaysecond = setTimeout(() => {
        setShowContent(true);
      }, 1010);
    }, 2000);

  }, [title]);
    const h1class = `startup ${showH1 ? 'act' : 'pas'}`;
    return (

      <div className="Onas">
      <NavbarMain isadmin = {isadmin}/>
      
      <div className="mainBox">
      
      <div className="photoBox photoBox_element">
        <h1><div className="naglowek_o_firmie">O FIRMIE</div></h1>
        <div className="Ofirmie">
          <div className="opis_o_firmie">
People of Quality to firma specjalizująca się w profesjonalnej rekrutacji na najwyższym poziomie dla sektora Jakości w branży Automotive. 
Naszą ambicją jest zbudowanie największej społeczności Pracowników działu Jakości w Polsce. 
W celu zachowania najwyższych standardów świadczonych przez nas usług, nieustannie poszerzamy swoją wiedzę w oparciu o najnowsze wymagania branży, co jest gwarantem sukcesu i efektywności prowadzonych procesów.</div>
<img src={firmalogo} alt="firmalogo" className="imgOnas" /></div>
          
          <h1><div className="naglowek">O NAS</div></h1>
          <div className="Ons">
          <div className="Onas_css_kevin Onas_css_element"><img src={kevin_photo} alt="single_photo" /><div>
            <div className="onas_tekst_kevin" >Jestem absolwentem Politechniki Krakowskiej oraz Akademii Górniczo-Hutniczej w Krakowie. 
            Pracuję w branży motoryzacyjnej nieprzerwanie od 2015 roku w międzynarodowych firmach korporacyjnych w Polsce. 
            Posiadam dużą wiedzę w zakresie jakości popartą certyfikatami audytora ISO, IATF, a także VDA QMC. 
            Współpracowałem z największymi Klientami OEM na świecie takimi jak BMW, Volvo, VW, Daimler, Renault, Stellantis. 
            Jestem specjalistą z zakresu APQP, PPAP, FMEA, SPC, MSA, 8D, QRQC również potwierdzoną certyfikatami. 
            Przez ścieżkę kariery pracowałem zarówno na Produkcji jak i rozwijałem najnowsze Projekty Greenfield.
            </div></div>
          </div>
          <div className="Onas_css_olek Onas_css_element"><img src={aleksander_photo} alt="single_photo"  /><div>
            <div className="onas_tekst_olek">
            Jestem absolwentem Akademii Górniczo-Hutniczej w Krakowie. Swoją karierę w świecie automotive rozpocząłem w 2019 roku.
            Pracowałem w międzynarodowych korporacjach będącymi Tier1 oraz Tier2 w łańcuchu dostaw. 
            Moje dotychczasowe doświadczenie poparte jest sukcesami w optymalizacji produkcji seryjnej jak również we wdrażaniu nowych projektów oraz linii produkcyjnych. 
            Posiadam certyfikaty potwierdzające znajomość narzędzi jakościowych takich jak PFMEA, SPC, MSA, IATF, CP, ISO. 
            Zarówno jako Lider Projektu oraz Inżynier Jakości ściśle współpracowałem z Klientami OEM takimi jak: BMW, VW, Renault, Stellantis.
          </div>
          </div>
        </div>
        </div>
      </div>

      </div>
      <footer className={`git ${isFooterVisible ? 'visible' : ''}`}>
      <p>Copyright © 2023 peopleofquality.pl - All Rights Reserved.</p>
      <p className="polityka-link"><Link to="/Warunki"><span>Polityka prywatności</span><br /><br /></Link><Link to="/Regulamin"><span> Regulamin strony</span></Link> </p>
    </footer>
    </div>
      
    );
  }
  
export default ONas;