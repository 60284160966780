import React, {useState} from "react";
import "./Offers.css"
function UserOffer({ firma, wojewodztwo,stanowisko, best, NotVer, id_}, visible, handleAplikowanie){
  const apiurl = "https://peopleofqualityback.azurewebsites.net"
  /// const apiurl = "http://127.0.0.1:5000"
    const [dropped, setDropped] = useState(false);
    const [favorite, setFavorite] = useState(true);
    //const liked = JSON.parse(localStorage.getItem("liked")) ?? [];
    //const isLiked = liked.includes(id_);

    const handleToggleFavorite = (id) => {
      setFavorite(!favorite);
      
      // Use axios.post() here if needed
    };
    const handleDropdownToggle = () => {
      setDropped(!dropped);
      // console.log(dropped)
    };
    const offerBoxClass = `OfferBoxWelcomeToggle`;
    return (
      <div className={offerBoxClass}>
        <div onClick={handleDropdownToggle}>
        {visible ?
          <div>
            <div className="log_box"></div>
              <p>Oferta {id_}</p>
              <li>Firma: {firma}</li>
              <li>Województwa pracy: {wojewodztwo}</li>
              <li>Stanowisko: {stanowisko}</li>
              <li >
                Użytkownicy:
                {Array.isArray(best) && best.length > 0 ? (
                  <ul>
                    {best.map((user, index) => (
                      <li key={index}>{user}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No users available</p>
                )}
              </li>
              <li >
                Niezweryfikowani Użytkownicy:
                {Array.isArray(NotVer) && NotVer.length > 0 ? (
                  <ul>
                    {NotVer.map((user, index) => (
                      <li key={index}>{user}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No users available</p>
                )}
              </li>

  
      
          </div> : <div></div>
        }
        </div>
        
       
      </div>
    )



};

export default UserOffer;
