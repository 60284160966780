import React, {useEffect, useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import Login from './pages/Login.js';
import HomePage from './pages/HomePage';
import UserProfile from './pages/UserProfile';
import Forgot from './pages/Forgot';
import Ankieta from './Components/Ankieta';
import DodajAnkiete from './pages/DodajOferte.js';
import Uzytkownicy from './pages/Uzytkownicy.js';
import ListaUzytkownikow from './pages/ListaUzytkownikow.js';
import ONas from './pages/ONas.js';
import ToFind from './pages/ToFind.js';
import Kontakt from './pages/Kontakt.js';
import Best from './pages/ListaBest.js';
import Regulamin from './pages/Regulamin.js';
import Warunki from './pages/Warunki.js';
import SendEmail from './pages/PassReset.js';
function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const apiUrl = "http://127.0.0.1:5000";
    useEffect(() => {
        
        const token = localStorage.getItem('token'); 
        if (token !== "") {
        
        setIsAuthenticated(true);
        }
       if (localStorage.getItem('email') === "kevin.stala@peopleofquality.pl" || localStorage.getItem('email') === "biuro@peopleofquality.pl" || localStorage.getItem('email') === "aleksander.kuspak@peopleofquality.pl" || localStorage.getItem('email') === "test@pl.pl"){
           setIsAdmin(true)
       }
    }, []); 

    const [isAdmin, setIsAdmin] = useState(false);


    return (
        <Router>
            <Routes>
                <Route exact path='/Oferty' element={<WelcomePage title ='People Of Quality' apiurl={apiUrl} Login={isAuthenticated} isadmin={isAdmin}/>}/>
                <Route path='/Login' element={<Login title ='Login'  tryb ={"Login"}/>} />
                <Route path='/Rejestracja' element={<Login title ='Login' tryb={"Sign Up"} />} />
                <Route path='/' element={<HomePage title ='People Of Quality' Login={isAuthenticated}/>} />
                <Route path='/UserProfile' element={<UserProfile title='UserProfile' apiurl={apiUrl} isadmin={isAdmin}/>} />
                <Route path='/Zmiana' element={<Forgot title='Forgot password??' apiurl={apiUrl} />} />
                <Route path='/Ankieta' element={<Ankieta title='Ankieta'  apiurl={apiUrl} isadmin={isAdmin}/>}/>  
                <Route path='/Forgot' element={<SendEmail title='Forgot' />} />

                {isAdmin && (
                    <>
                        <Route path='/Dodaj' element={<DodajAnkiete title='Dodaj' apiurl={apiUrl} isadmin={isAdmin} />} />
                        <Route path='/Uzytkownicy' element={<Uzytkownicy title='Uzytkownicy' isadmin={isAdmin} apiurl={apiUrl}/>} />
                        <Route path='/ListaUzytkownikow' element={<ListaUzytkownikow title='ListaUzytkownikow' isadmin={isAdmin} apiurl={apiUrl}/>} />
                        <Route path='/Najlepsi' element={<Best title='Najlepsi' isadmin={isAdmin} apiurl={apiUrl}/>} />
                    </>
                )}
                
                <Route path='/O-nas' element={<ONas title='O-nas' apiurl={apiUrl} isadmin={isAdmin}/>}/> 
                <Route path='/ToFind' element={<ToFind title='ToFind' apiurl={apiUrl} isadmin={isAdmin}/>} />
                <Route path='/Kontakt' element={<Kontakt title='Kontakt' apiurl={apiUrl} isadmin={isAdmin}/>} />
                <Route path='/Regulamin' element={<Regulamin title='Regulamin' apiurl={apiUrl} isadmin={isAdmin}/>} />
                <Route path='/Warunki' element={<Warunki title='Warunki' apiurl={apiUrl} isadmin={isAdmin}/>} />
            </Routes>
        </Router>
    );
}



export default App;