import React, {useState, useEffect} from "react";
import "./Kontakt.css"

import { Link } from 'react-router-dom';
import NavbarMain from "../Components/NavbarMain";
import axios from "axios";
import FooterBlur from "../Components/footer_blur";
function Kontakt ({title ,Login, isadmin }) {
  const [isFooterVisible, setIsFooterVisible] = useState(false);
    useEffect(() => {
        document.title = title || 'People Of Quality';
      }, [title]);

      return (
        <div className="Kontakt">
           <NavbarMain isadmin = {isadmin}/>
           <div>
            <div className="maile">
              <div className="kontakt_tekst">
                <ul>
                    <h2 className="masz_pytania" >Masz pytania? <br />Napisz do nas na adres:</h2>
                    <li>biuro@peopleofquality.pl</li>
                    <li>PEOPLE OF QUALITY KUŚPAK I STALA spółka jawna <br /> KRS 0001084610  <br />NIP 9442286713</li>
                    <li>Aleksander Kuśpak: +48 607 194 979 <br /> Kevin Stala: +48 501 273 853</li>
                    
                </ul>
                </div>
              </div>
             
            </div>
            <div>
          
            </div>
            <footer className={`git_kontakt ${isFooterVisible ? 'visible' : ''}`}>
      <p>Copyright © 2023 peopleofquality.pl - All Rights Reserved.</p>
      <p className="polityka-link"><Link to="/Warunki"><span>Polityka prywatności</span><br /><br /></Link><Link to="/Regulamin"><span> Regulamin strony</span></Link> </p>
    </footer>
        </div>
        
      );
};

export default Kontakt;