import React, { useEffect, useState } from "react";
import "./HomePage.css";
import Logo from '../Components/images/pq-logo.png';
import axios from "axios";

function HomePage({ title, Login }) {
    // const apiurl = "http://127.0.0.1:5000";
    const apiurl = "https://peopleofqualityback.azurewebsites.net"
    const [showCookieConsent, setShowCookieConsent] = useState(false);
    useEffect(() => {
        document.title = title || 'People Of Quality';
        getcookieinfo();
    }, [title]);
    const nie = false;
    const handleCircleClick = () => {
        window.location.href = '/Oferty';
    };

    const getcookieinfo = () => {
        console.log("COOKIUE")
        console.log(Login)
        if (Login) {
          const email = localStorage.getItem('email');
          axios.get(`${apiurl}/api/cookies`, {
            params: { email: email}
        })
        .then(response => {
          if (response.data.message === "got value") {
            setShowCookieConsent(false)
          } else {
              throw new Error("Error fetching user data");
          }
      })
      .catch(error => {
          console.error(error);
      });
    
        }
        else {
            setShowCookieConsent(true)
        } 
    
      }
      const handleAcceptCookies = (value) => {
        if (value === "tak") {
            setShowCookieConsent(false);

            if (Login) {
                const email = localStorage.getItem('email');
                axios.post(`${apiurl}/api/cookies`, {"email": email, "cookie": true})
                    .then((response) => {
                        if (response.data.message === "added value") {
                            console.log("zapisano informacje o polityce cookie");
                        } else {
                            console.log("wystąpił błąd");
                        }
                    })
                    .catch((error) => {
                        console.error("Error calling backend API:", error);
                    });
            } else {
              setShowCookieConsent(false);
       
            }
        } 
        if (value === "nie") {
    
            setShowCookieConsent(false);

            if (Login) {
                const email = localStorage.getItem('email');
                axios.post(`${apiurl}/api/cookies`, {"email": email, "cookie": false})
                    .then((response) => {
                        if (response.data.message === "added value") {
                            console.log("zapisano informacje o polityce cookie");
                        } else {
                            console.log("wystąpił błąd");
                        }
                    })
                    .catch((error) => {
                        console.error("Error calling backend API:", error);
                    });
            } else {
              setShowCookieConsent(false);

            }
        }
    };
    return (
        <div className="HOME" onClick={handleCircleClick}>
            
            <div className="center_circle">
                <div className="home_containeraaaa">
                    <div className="centered-image">
                        <img className="img_home" src={Logo} alt="Logo" />
                    </div>
                </div>
            </div>
            <span className="lol">
                Witaj na stronie <b>People of Quality</b>!
                Kliknij i stań się członkiem największej społeczności Automotive!
            </span>
            {nie &&  (
                <div className="cookie-consent">
                    <p>
                        Czy wyrażasz zgode na przechowywanie plików cookie?<br></br>
                        </p>
                        <div className="cookie_row">
                            <button className="accept-button" onClick={() => handleAcceptCookies("tak")}>Zgadzam się</button>
                            <button className="reject-button" onClick={() => handleAcceptCookies("nie")}>Nie zgadzam się</button>
                        </div>
                    
                </div>
            )}

        </div>
    );
}

export default HomePage;
