import React, { useEffect, useState } from "react";
import NavbarMain from "../Components/NavbarMain";
import axios from "axios";
import UserOffer from "../Components/UserOffer";
import "./WelcomePage.css";


function Uzytkownicy({ title, isadmin}){
    // const apiurl = "http://127.0.0.1:5000";

    const apiurl = "https://peopleofqualityback.azurewebsites.net"
    const [allOffers, setAllOffers] = useState([]);

    useEffect(() => {
        document.title = title || 'People Of Quality';
        getOffers();

      }, [title]);



    const getOffers = async () => {
        try {
            const response = await axios.post(`${apiurl}/api/AllOffers`, {"title": title});

            if (response.data.message === "got details") {
                setAllOffers(response.data.data)
                // console.log(allOffers);
            }
        } catch (error) {
            console.error("Error getting offers", error);
        }
    };


    return (
        <div>
            <NavbarMain isadmin = {isadmin}/>
            <div className="oferta_best">    
              {allOffers.map((offer) => (
                <UserOffer
                key={offer._id}
                firma={offer.Firma || ''}
                wojewodztwo={(offer.Wojewodztwa || []).join(", ")}
                stanowisko={(offer.Pozycja || []).join(", ")}
                tryby={(offer.Tryby || []).join(", ")}
                sposob={(offer.Sposob || []).join(", ")}
                opis={offer.Streszczenie || ''}
                umiejetnosci={(Object.keys(offer.Skills) || []).join(", ")}
                applied={(offer.Applied || []).join(", ")}
                id_={offer._id}
                visible={true}
              />
              ))}
            </div>
           
        </div>
    )
}


export default Uzytkownicy;