import React, { useState, useEffect } from "react";
import "./footer_blur.css";
import { Link } from 'react-router-dom';
function FooterBlur() {
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;

      if (scrolled + windowHeight >= documentHeight - 50) {
        setIsFooterVisible(true);
      } else {
        setIsFooterVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer className={`git ${isFooterVisible ? 'visible' : ''}`}>
    <p>Copyright © 2023 peopleofquality.pl - All Rights Reserved.</p>
    <p className="polityka-link"><Link to="/Warunki"><span>Polityka prywatności</span><br /><br /></Link><Link to="/Regulamin"><span> Regulamin strony</span></Link> </p>
  </footer>
  );
}

export default FooterBlur;