import React, {useState, useEffect} from "react";
import axios from "axios";
import './Forgot.css'
import NavbarMain from "../Components/NavbarMain";
function SendEmail({title, isadmin}) {
    const apiurl = "https://peopleofqualityback.azurewebsites.net"
    // const apiurl = "http://127.0.0.1:5000";
    const [email, SetEmail] = useState('')
    const SendEmailPassword = () => {

        axios.post(`${apiurl}/api/SendEmail`, {"email": email})
        .then((response) => {
            if (response.data.message === "Sent") {
                window.alert("Mail został wysłany pomyslnie");

            } else {
                window.alert("Nastąpił błąd podczas wysyłania maila")
            }
        })
        .catch(error => {
            console.error("Wystąpił błąd:", error);
        });

    }



    return (
    <div className="Forgot">
        <NavbarMain isadmin={isadmin}/>
        <div className="containerForgot">
            <div style={{textAlign: "center"}}>
                Podaj swojego maila a następnie na twoją skrzynke zostanie wysłane nowe hasło
            </div>
                <div className="inputForgot">
                <input 
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => {
                    SetEmail(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      SendEmailPassword();
                    }}} 
                />
                </div>
                <button className="ForgotPassButton" onClick={()=>SendEmailPassword()}>Wyślij mail</button>
         </div>




    </div>);
}

export default SendEmail;