import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import "./regulamin.css"
import NavbarMain from "../Components/NavbarMain";
function Regulamin({title, isadmin, Login}) {
    useEffect(() => {
        document.title = title || 'People Of Quality';
        

      }, [title]);


      return (
        <div className="regulamin_css">
          <NavbarMain isadmin = {isadmin}/><br /><br /><br /><br /><br />
      <h1 className="regulamin_tresc">Regulamin</h1>
      <div>
        <p>
          Witamy w People of Quality! Niniejszy regulamin określa zasady
          korzystania ze Strony internetowej People of Quality, dostępnej pod
          adresem www.peopleofquality.pl. Użytkownik wchodząc na Stronę
          internetową, akceptuje niniejsze warunki. W przypadku braku akceptacji
          niniejszych warunków Użytkownik powinien opuścić Stronę People of
          Quality.
        </p>

        <p>
          Dane Firmy:<br />
          PEOPLE OF QUALITY KUŚPAK I STALA SPÓŁKA JAWNA<br />
          Numer KRS 0001084610<br />
          NIP 9442286713
        </p>

        <p>
          1) Zakres działania People of Quality: People of Quality to firma
          specjalizująca się w profesjonalnej rekrutacji na najwyższym poziomie
          dla sektora Jakości w branży Automotive. Naszą ambicją jest zbudowanie
          największej społeczności Pracowników działu Jakości w Polsce. W celu
          zachowania najwyższych standardów świadczonych przez nas usług,
          nieustannie poszerzamy swoją wiedzę w oparciu o najnowsze wymagania
          branży, co jest gwarantem sukcesu i efektywności prowadzonych
          procesów.
        </p>

        <p>
          2) Warunki świadczenia usług:
        </p>

        <ul>
          <li>Użytkownikiem profilu może być tylko i wyłącznie osoba pełnoletnia.</li>
          <li>Aby założyć profil, użytkownik musi zaakceptować regulamin serwisu.</li>
          <li>Na stronie PeopleofQuality.pl zamieszczane są oferty Klientów firmy People of Quality.</li>
          <li>Jeśli Użytkownik jest zainteresowany ofertą pracy, może złożyć swoje CV, co jest jednoznaczne z założeniem Profilu na stronie www.peopleofquality.pl.</li>
          <li>Użytkownik zakładający Konto musi wpisać podstawowe dane: imię i nazwisko oraz adres e-mail.</li>
          <li>Po założeniu Konta, Użytkownik powinien wypełnić ankietę oceniającą umiejętności jakościowe takie jak: SPC, MSA, FMEA, CP, QRQC i inne.</li>
        </ul>

        <p>
          3) Założenie profilu na stronie People of Quality:
        </p>

        <ul>
          <li>Użytkownik ma możliwość założenia własnego Profilu bez składania aplikacji na żadne ogłoszenie.</li>
          <li>Użytkownik zostanie poproszony o konkretne dane takie jak imię i nazwisko oraz adres e-mail.</li>
          <li>Aby założyć Profil, Użytkownik musi zaakceptować regulamin serwisu.</li>
          <li>Jeśli Użytkownik założy Profil na adres email, zostaje wysłana informacja o założeniu Profilu. Na adres e-mail Użytkownik będzie otrzymywał powiadomienia z serwisu peopleofquality.pl. Użytkownik może aktualizować/zmieniać dane osobowe podczas obsługi swojego Profilu. Użytkownik powinien podać prawidłowe dane podczas rejestracji, w przypadku danych nieprawdziwych proces rekrutacji Użytkownika może zostać w pełni anulowany, a Użytkownik może zostać usunięty z bazy Użytkowników www.peopleofquality.pl.</li>
        </ul>

        <p>
          4) Gromadzenie danych o Usługobiorcach: W celu prawidłowego świadczenia usług przez Serwis, zabezpieczenia prawnego interesu Usługodawcy oraz w celu zapewnienia zgodności działania Serwisu z obowiązującym prawem, Usługodawca za pośrednictwem Serwisu gromadzi i przetwarza niektóre dane o Użytkownikach.
        </p>

        <p>
          5) Pliki cookie: Na tej Stronie internetowej wykorzystujemy pliki cookie, aby spersonalizować doświadczenia online naszych odbiorców. Wchodząc na Stronę People of Quality, wyrażasz zgodę na używanie wymaganych plików cookie.
        </p>

        <p>
          Plik cookie to plik tekstowy, który jest umieszczany przez serwer strony internetowej na dysku twardym komputera Użytkownika. Pliki cookie nie mogą być wykorzystywane do uruchamiania programów lub przenoszenia wirusów na komputer Użytkownika. Pliki cookie są ściśle przypisane do danego użytkownika i mogą być odczytane tylko przez serwer internetowy w domenie, która wygenerowała plik cookie.
        </p>

        <p>
          Pliki cookie mogą być wykorzystywane do zbierania, przechowywania i śledzenia informacji w celach statystycznych lub marketingowych związanych z prowadzeniem naszej Strony internetowej. Użytkownik ma prawo zaakceptować lub odmówić zaakceptowania opcjonalnych plików cookie. Niektóre pliki cookie są wymagane i niezbędne do prawidłowego funkcjonowania Strony internetowej. Takie pliki cookie nie wymagają zgody, ponieważ są aktywne przez cały czas. Pamiętaj, że akceptując wymagane pliki cookie, akceptujesz również pliki cookie stron trzecich, które mogą być wykorzystywane przez inne podmioty podczas korzystania z usług świadczonych przez te podmioty.
        </p>
        <p>6) Licencja:</p>
  <p>
    O ile nie stwierdzono inaczej, firma People of Quality lub jej licencjodawcy są właścicielami praw własności intelektualnej wszystkich materiałów zamieszczonych na Stronie PeopleofQuality.pl. Wszelkie prawa własności intelektualnej zostały zastrzeżone. Użytkownicy mogą uzyskać dostęp materiałów dostępnych na Stronie PeopleofQuality.pl na własny użytek, z zastrzeżeniem ograniczeń określonych w niniejszym regulaminie.
  </p>

  <p>
    <strong>Zabrania się:</strong>
  </p>
  <ul>
    <li>Kopiowania lub ponownego publikowania materiałów umieszczonych na Stronie PeopleofQuality.pl</li>
    <li>Sprzedaży, wypożyczania lub udzielania sublicencji na materiały umieszczone na Stronie PeopleofQuality.pl</li>
    <li>Odtwarzania, powielania lub kopiowania materiałów zamieszczonych na Stronie PeopleofQuality.pl</li>
    <li>Ponownego rozpowszechniania treści zamieszczonych na Stronie PeopleofQuality.pl</li>
  </ul>

  <p>
    Niniejsza umowa zaczyna obowiązywać w dniu jej sporządzenia. Użytkownicy mają możliwość zamieszczania i wymiany opinii oraz informacji w niektórych częściach niniejszej Strony internetowej. People of Quality nie filtruje, nie edytuje, nie publikuje ani nie sprawdza komentarzy przed ich umieszczeniem na Stronie. Komentarze nie odzwierciedlają poglądów i opinii People of Quality, jej przedstawicieli ani podmiotów stowarzyszonych. Zamieszczone komentarze odzwierciedlają poglądy i opinie osoby, która je zamieściła. W zakresie przewidzianym przez obowiązujące prawo People of Quality nie odpowiada za komentarze ani nie ponosi odpowiedzialności za jakiekolwiek zobowiązania, szkody lub wydatki spowodowane lub poniesione w wyniku wykorzystania, zamieszczenia lub pojawienia się komentarzy na niniejszej Stronie.
  </p>

  <p>
    People of Quality zastrzega sobie prawo do monitorowania wszystkich komentarzy i usuwania tych, które można uznać za nieodpowiednie, obraźliwe lub naruszające niniejsze warunki.
  </p>

  <p>
    <strong>Użytkownik zapewnia i oświadcza, że:</strong>
  </p>
  <ul>
    <li>Jest uprawniony do zamieszczania Komentarzy na naszej Stronie internetowej i posiada wszelkie niezbędne licencje i zgody, aby to robić;</li>
    <li>Komentarze nie naruszają praw własności intelektualnej, w tym między innymi praw autorskich, patentów lub znaków towarowych stron trzecich;</li>
    <li>Komentarze nie zawierają treści o charakterze oszczerczym, zniesławiającym, obraźliwym, nieprzyzwoitym lub w inny sposób niezgodnym z prawem, stanowiącym naruszenie prywatności.</li>
    <li>Komentarze nie będą wykorzystywane do pozyskiwania lub promowania działalności biznesowej lub zwyczajów, ani do prezentowania działań komercyjnych lub niezgodnych z prawem.</li>
  </ul>

  <p>
    Niniejszym Użytkownik udziela People of Quality niewyłącznej licencji na wykorzystywanie, powielanie, edytowanie i upoważnianie innych osób do wykorzystywania, powielania i edytowania jego komentarzy w dowolnej formie i we wszystkich formatach lub mediach.
  </p>
  <p>7) Zamieszczanie odnośników do naszych treści:</p>
  <p>
    Następujące podmioty mogą umieszczać odnośniki do naszych treści bez uprzedniej pisemnej zgody:
  </p>
  <ul>
    <li>Wyszukiwarki internetowe;</li>
    <li>Organizacje prasowe;</li>
    <li>Podmioty zajmujące się rozpowszechnianiem katalogów online mogą zamieszczać linki do naszej Strony w taki sam sposób, w jaki zamieszczają hiperłącza do stron internetowych innych wymienionych podmiotów.</li>
  </ul>

  <p>
    Organizacje te mogą zamieszczać odnośniki do naszej strony głównej, publikacji lub innych materiałów zamieszczonych na Stronie pod warunkiem, że odnośnik ten:
  </p>
  <ol>
    <li>Nie wprowadza w błąd;</li>
    <li>Nie sugeruje, że jest sponsorowany, promowany lub uznany przez stronę linkującą oraz jej produkty i/lub usługi, jeśli nie jest to zgodne z prawdą;</li>
    <li>Passuje do kontekstu strony linkującej.</li>
  </ol>

  <p>8) Odpowiedzialność za treść:</p>
  <p>
    Nie ponosimy odpowiedzialności za jakiekolwiek treści, które pojawiają się na innych stronach internetowych. Zobowiązujesz się do zapewnienia nam ochrony przed wszelkimi roszczeniami, które zostaną postawione wobec Twojej Strony. Link(i) nie powinny być umieszczane na Stronie, która może być postrzegana jako oszczercza, nieprzyzwoita lub niezgodna z prawem, lub która w inny sposób łamie, lub zachęca do łamania, prawa osób trzecich.
  </p>

  <p>9) Zastrzeżenia praw:</p>
  <p>
    Zastrzegamy sobie prawo do zażądania usunięcia wszystkich lub wybranych linków kierujących do naszej Strony. Podmioty linkujące zobowiązują się do natychmiastowego usunięcia wszystkich linków prowadzących do naszej Strony w przypadku otrzymania takiego żądania z naszej strony. Zastrzegamy sobie również prawo do zmiany niniejszych warunków i zasad linkowania w dowolnym momencie. Umieszczanie linków do naszej Strony oznacza akceptację i zobowiązanie do przestrzegania niniejszych zasad i warunków<br />
    <br />
    Zdjęcia na stronie www.peopleofquality.pl pochodzą ze strony: <br /> <br />
    &lt;a href=&quot;https://www.freepik.com/free-ai-image/metal-industry-workshop-modern-machinery-
building-steel-parts-generated-by-ai_41572477.htm&quot;&gt;Image By vecstock&lt;/a&gt;
  <br />
  <br />
  &lt;a href=&quot;https://www.freepik.com/free-photo/modern-automobile-production-line-automated-
production-equipment-shop-assembly-new-modern-cars-way-assembly-car-assembly-line-
plant_26150962.htm#query=automotive%20production&amp;position=3&amp;from_view=search&amp;track=ais&amp;u
uid=e92c6c95-99a6-4b9b-bae6-0770e8c5ae04&quot;&gt;Image by usertrmk&lt;/a&gt; on Freepik
  </p>

  <p>10) Usuwanie linków ze Strony:</p>
  <p>
    Jeśli zauważysz na Stronie link, który z jakichkolwiek powodów jest obraźliwy, skontaktuj się z nami i poinformuj nas o tym niezwłocznie. Rozpatrzymy prośbę o usunięcie linku. Nie zobowiązujemy się jednak do usunięcia takiego linku, ani do udzielenia bezpośredniej odpowiedzi osobie zgłaszającej.
  </p>
  <p>11) Wyłączenie odpowiedzialności:</p>
  <p>
    W maksymalnym zakresie dozwolonym przez obowiązujące prawo, nie udzielamy żadnych poręczeń, gwarancji i innych zobowiązań względem naszej strony internetowej i sposobu jej wykorzystywania. Żadne z postanowień niniejszej klauzuli wyłączenia odpowiedzialności nie będzie:
  </p>
  <ul>
    <li>Ograniczać lub wyłączać naszej lub Twojej odpowiedzialności za oszustwa lub świadome wprowadzanie w błąd;</li>
    <li>Ograniczać odpowiedzialności naszej lub Twojej w jakikolwiek sposób, który nie jest zgodny z obowiązującym prawem; lub</li>
    <li>Wyłączać odpowiedzialności naszej lub Twojej, która nie może zostać wyłączona na mocy obowiązującego prawa.</li>
  </ul>

  <p>
    Ograniczenia i wyłączenia odpowiedzialności zawarte w tym ustępie oraz w innych miejscach tego wyłączenia odpowiedzialności:
  </p>
  <ol>
    <li>Nie naruszają postanowień poprzedniego ustępu;</li>
    <li>Regulują wszystkie zobowiązania wynikające z tego wyłączenia odpowiedzialności, w tym zobowiązania wynikające z umowy, z czynu niedozwolonego oraz z tytułu naruszenia obowiązków ustawowych.</li>
  </ol>

  <p>
    Pod warunkiem, że strona internetowa oraz informacje i usługi na niej zawarte są udostępniane nieodpłatnie, nie ponosimy odpowiedzialności za powstanie jakichkolwiek strat lub szkód o dowolnym charakterze.
  </p>

  <p>12) Postanowienia końcowe:</p>
  <ul>
    <li>PeopleofQuality.pl ma możliwość wprowadzenia zmian w Regulaminie, użytkownicy serwisu zostaną powiadomieni w terminie 21 dni od czasu wprowadzenia zmian drogą mailową;</li>
    <li>Wszystkie inne sprawy poza tym Regulaminem mają zastosowanie przepisy Kodeksu Cywilnego oraz ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną (Dz. U. nr 144 poz. 1204), ustawy o ochronie danych osobowych oraz RODO i inne bezwzględnie obowiązujące przepisy prawa.</li>
  </ul>
      </div>
    </div>
      );
}

export default Regulamin;