import React, {useState, useEffect, useRef} from "react";
import "./DodajeOferte.css"
import axios from "axios";
import NavbarMain from "../Components/NavbarMain";

function DodajAnkiete ( {title, isadmin}) {
    const apiurl = "https://peopleofqualityback.azurewebsites.net"
    //const apiurl = "http://127.0.0.1:5000"
    const fileInputRef = useRef(null);
    const allowedExtension = ['jpg', 'png'];
    useEffect(() => {
        document.title = title || 'People Of Quality';
      }, [title]);
    
    const handleAddPhoto = () => {
        // console.log("dodaj zdjęcie");
        fileInputRef.current.click();  
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [firmaDane, setFirmaDane] = useState("");

    const [stanDane, setStanDane] = useState("");

    const [miasto, setMiasto] = useState("");

    const [streszczenie, setStreszczenie] = useState("");
    const [dokladny, setDokladny] = useState("");
    const [selectedWojewody, setSelectedWojewody] = useState([]);
    const [selectedTryb, setSelectedTryb] = useState([]);
    const [selectedSposob, setSelectedSposob] = useState([]);
    const [selectedPozycja, setSelectedPozycja] = useState([]);

    const [Wymagania, setWymagania] = useState('');
    const [listaWymagania, setListaWymagania] = useState([]);
    
    const [responsibilities, setResponsibilities] = useState('');
    const [listaRes, setListaRes] = useState([]);
    
    const [Oferujemy, setOferujemy] = useState('');
    const [listaOfer, setListaOfer] = useState([]);
    
    const [isDiv2Expanded, setIsDiv2Expanded] = useState(false);
    const tryb_pracy = [
        "Zdalny",
        "Hybrydowy",
        "Stacjonarny"
    ]
    const questions = [
        "Control Plan",
        "Flow Chart",
        "QRQC",
        "PPAP",
        "8D",
        "FMEA",
        "FTA",
        "SPC",
        "MSA",
        "A3",
        "Pareto",
        "Ishikawa",
        "5Why",
        "PPM & TLR",
        "5W2H",
        "PDCA",
        "FPD",
        "IATF",
        "Run at Rate",
       ];
    const wojewody = [
        "Dolnośląskie",
        "Kujawsko-Pomorskie",
        "Lubelskie",
        "Lubuskie" ,
        "Łódzkie" ,
        "Małopolskie",
        "Mazowieckie",
        "Opolskie",
        "Podkarpackie",
        "Podlaskie",
        "Pomorskie",
        "Śląskie",
        "Świętokrzyskie",
        "Warmińsko-Mazurskie",
        "Wielkopolskie",
        "Zachodniopomorskie"
    ];
    const sposob_zatrudnienia = [
        "Kontrakt B2B",
        "Zlecenie",
        "Umowa o pracę",
        "Umowa o dzieło",
        "Umowa na zastępstwo",

    ];
    const poziom_stanowiska = [
        "Specjalista",
        "Inżynier",
        "Kierownik",
        "Manager",
        "Dyrektor",
        "Technik",

    ];
    const [questionRatings, setQuestionRatings] = useState({});
    
    
    const handleFileChange = (event) => {
        const newFile = event.target.files[0];
        // console.log("Selected file:", newFile);
        const fileExtension = newFile.name.split(".").at(-1);

        if (!allowedExtension.includes(fileExtension)) {
            window.alert(`File does not support. Files type must be PNG`);
            return false;
          }
        setSelectedFile(newFile)
        setSelectedFileName(newFile.name)
    }

    const handleDaneFirma = (event) => {
        const newValue = event.target.value;
        setFirmaDane(newValue);

    }
    const handleDaneStan = (event) => {
        const newValue = event.target.value;
        setStanDane(newValue);

    }
    const handleDaneMiasto = (event) => {
        const newValue = event.target.value;
        setMiasto(newValue);

    }
    const handleStreszczenie= (event) => {
        const newValue= event.target.value;
        setStreszczenie(newValue);

    }
    const handleDokladny = (event) => {
        const newValue = event.target.value;
        setDokladny(newValue);

    }

    const handleSendDane = () => {
        const formData = new FormData();
        console.log(firmaDane);
        console.log(streszczenie);
        console.log(dokladny);
        console.log(questionRatings);
        console.log(selectedWojewody)
        console.log(selectedPozycja)
        console.log(selectedTryb)
        console.log(selectedSposob)
        setDokladny("");
        setStreszczenie("");
        setFirmaDane("");
        formData.append("daneFirma", firmaDane);
        formData.append("streszczenie", streszczenie);
        formData.append("dokladny", dokladny);
        formData.append("miasto", miasto);
        formData.append("nazwaStan", stanDane);
        formData.append("rating", JSON.stringify(questionRatings));
        formData.append("logo", selectedFile)
        formData.append("Woj", JSON.stringify(selectedWojewody));
        formData.append("Poz", JSON.stringify(selectedPozycja));
        formData.append("Tryb", JSON.stringify(selectedTryb));
        formData.append("Spos", JSON.stringify(selectedSposob));
        formData.append("Responsibilities", JSON.stringify(listaRes));
        formData.append("Oferujemy", JSON.stringify(listaOfer));
        formData.append("Wymagania", JSON.stringify(listaWymagania));

        axios.post(`${apiurl}/api/dodajoferte`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            // ... other headers
        },
    })
            .then((response) => {
                console.log(response.data);
                if (response.data.message === "Offer added successfully") {
                    window.alert("Oferta została poprawnie dodana do bazy danych")
                }
            })
            .catch((error) => {
                console.error(error);
            })
            
    }

    const handleRatingChange = (question, rating) => {
        setQuestionRatings((prevRatings) => ({
            ...prevRatings,
            [question]: rating,

            ...Object.fromEntries(
                questions
                    .filter((q) => !prevRatings.hasOwnProperty(q))
                    .map((q) => [q, "1"])
            ),
        }));
    };
    const handleWojChange = (woj) => {
        if (selectedWojewody.includes(woj)) {
          setSelectedWojewody((prevWojewody) =>
            prevWojewody.filter((selected) => selected !== woj)
          );
        } else {
          setSelectedWojewody((prevWojewody) => [...prevWojewody, woj]);
        }
      };
    const handleTrybChange = (tryb) => {
        if (selectedTryb.includes(tryb)) {
          setSelectedTryb((prevWojewody) =>
            prevWojewody.filter((selected) => selected !== tryb)
          );
        } else {
          setSelectedTryb((prevWojewody) => [...prevWojewody, tryb]);
        }
      };
    const handleSposChange = (spos) => {
        if (selectedSposob.includes(spos)) {
          setSelectedSposob((prevWojewody) =>
            prevWojewody.filter((selected) => selected !== spos)
          );
        } else {
          setSelectedSposob((prevWojewody) => [...prevWojewody, spos]);
        }
      };
      const handlePosChange = (pos) => {
        if (selectedPozycja.includes(pos)) {
          setSelectedPozycja((prevWojewody) =>
            prevWojewody.filter((selected) => selected !== pos)
          );
        } else {
          setSelectedPozycja((prevWojewody) => [...prevWojewody, pos]);
        }
      };
    
const handleRemoveWymagania = (index) => {
    const updatedListaWymagania = [...listaWymagania];
    updatedListaWymagania.splice(index, 1);
    setListaWymagania(updatedListaWymagania);
};

const handleWymaganiaChange = (event) => {
    setWymagania(event.target.value);
};

const handleAddWymagania = () => {
    if (Wymagania.trim() !== '') {
        setListaWymagania(prevListaWymagania => [...prevListaWymagania, Wymagania]);
        setWymagania('');
    }
};

const handleRemoveResponsibilities = (index) => {
    const updatedListaRes = [...listaRes];
    updatedListaRes.splice(index, 1);
    setListaRes(updatedListaRes);
};

const handleResponsibilitiesChange = (event) => {
    setResponsibilities(event.target.value);
};

const handleAddResponsibilities = () => {
    if (responsibilities.trim() !== '') {
        setListaRes(prevListaRes => [...prevListaRes, responsibilities]);
        setResponsibilities('');
    }
};

const handleRemoveOferujemy = (index) => {
    const updatedListaOfer = [...listaOfer];
    updatedListaOfer.splice(index, 1);
    setListaOfer(updatedListaOfer);
};

const handleOferujemyChange = (event) => {
    setOferujemy(event.target.value);
};

const handleAddOferujemy = () => {
    if (Oferujemy.trim() !== '') {
        setListaOfer(prevListaOfer => [...prevListaOfer, Oferujemy]);
        setOferujemy('');
    }
};
    return (
        <div className="parent">
            <NavbarMain isadmin = {isadmin}/>
            <div className="OfferBoxDodaj" >
                <div className="LogoDodaj" onClick = {handleAddPhoto}>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    /><span>{selectedFileName !== "" ? selectedFileName : "Dodaj logo firmy"}</span>
                </div>
                <div className="InputsArea">
                    <ul>
                        <li>
                            <textarea className="INPUT" placeholder="Firma" onChange={handleDaneFirma} value={firmaDane || ""}></textarea>
                        </li>
                        <li>
                            <textarea className="INPUT" placeholder="Nazwa stanowiska" onChange={handleDaneStan} value={stanDane || ""}></textarea>
                        </li>
                        <li>
                            <textarea className="INPUT" placeholder="Miasto" onChange={handleDaneMiasto} value={miasto || ""}></textarea>
                        </li>
                        <li>
                        <div className={`okienko_dodaj ${isDiv2Expanded ? 'expanded' : ''}`}>
                            <div className="srodek_div2">
                            <p>CO OFERUJEMY</p>
                            {listaOfer.map((firma, index) => (
                                <div key={index} className="firma-item">
                                <input className="wpisane_ankieta"  value={firma} readOnly />
                                <button className="przyciski_usun" onClick={() => handleRemoveOferujemy(index)}>
                                    X
                                </button>
                                </div>
                            ))}
                            <div className="firma-item">
                                <input className="TekstAnkiety"
                                type="text"
                                value={Oferujemy}
                                onChange={handleOferujemyChange}
                                />
                                <button className="przyciski_dodaj" onClick={handleAddOferujemy}>
                                Dodaj
                                </button>
                            </div>
                            </div>
                        </div>
                        </li>
                        <li>
                        <div className={`okienko_dodaj ${isDiv2Expanded ? 'expanded' : ''}`}>
                            <div className="srodek_div2">
                            <p>ZAKRES OBOWIĄZKÓW:</p>
                            {listaRes.map((firma, index) => (
                                <div key={index} className="firma-item">
                                <input className="wpisane_ankieta"  value={firma} readOnly />
                                <button className="przyciski_usun" onClick={() => handleRemoveResponsibilities(index)}>
                                    X
                                </button>
                                </div>
                            ))}
                            <div className="firma-item">
                                <input className="TekstAnkiety"
                                type="text"
                                value={responsibilities}
                                onChange={handleResponsibilitiesChange}
                                />
                                <button className="przyciski_dodaj" onClick={handleAddResponsibilities}>
                                Dodaj
                                </button>
                            </div>
                            </div>
                        </div>
                        </li>
                        <li>
                        <div className={`okienko_dodaj ${isDiv2Expanded ? 'expanded' : ''}`}>
                            <div className="srodek_div2">
                            <p>WYMAGANIA:</p>
                            {listaWymagania.map((firma, index) => (
                                <div key={index} className="firma-item">
                                <input className="wpisane_ankieta"  value={firma} readOnly />
                                <button className="przyciski_usun" onClick={() => handleRemoveWymagania(index)}>
                                    X
                                </button>
                                </div>
                            ))}
                            <div className="firma-item">
                                <input className="TekstAnkiety"
                                type="text"
                                value={Wymagania}
                                onChange={handleWymaganiaChange}
                                />
                                <button className="przyciski_dodaj" onClick={handleAddWymagania}>
                                Dodaj
                                </button>
                            </div>
                            </div>
                        </div>
                        </li>
                    </ul>

                    <div className="checkboxdodaj">
                        <h3>Umiejętności</h3>
                    {questions.map((question) => (
                            <div key={question} className="questionItem">
                                <p>{question}</p>
                                <select
                                    value={questionRatings[question] || "1"}
                                    onChange={(e) =>
                                        handleRatingChange(question, e.target.value)
                                    }
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        ))}
                    </div>
                    <h3>Województwo</h3>
                    <div className="checkboxedodajwoj">
                    {wojewody.map((woj) => (
                        <div key={woj} className="wojItem">
                        <input
                            type="checkbox"
                            id={woj}
                            checked={selectedWojewody.includes(woj)}
                            onChange={() => handleWojChange(woj)}
                        />
                        <label htmlFor={woj}>{woj}</label>
                        </div>
                    ))}
                    </div>
                    <h3>Tryb pracy</h3>
                    <div>
                    {tryb_pracy.map((tryb) => (
                    <div key={tryb}>
                        <input
                        type="checkbox"
                        id={tryb}
                        checked={selectedTryb.includes(tryb)}
                        onChange={() => handleTrybChange(tryb)}
                        />
                        <label htmlFor={tryb}>{tryb}</label>
                    </div>
                    ))}
                </div>
                <h3>Sposob zatrudnienia</h3>
                <div>
                    {sposob_zatrudnienia.map((spos) => (
                    <div key={spos}>
                        <input
                        type="checkbox"
                        id={spos}
                        checked={selectedSposob.includes(spos)}
                        onChange={() => handleSposChange(spos)}
                        />
                        <label htmlFor={spos}>{spos}</label>
                    </div>
                    ))}
                </div>
                <h3>poziom Stanowiska</h3>
                <div>
                    {poziom_stanowiska.map((pos) => (
                    <div key={pos}>
                        <input
                        type="checkbox"
                        id={pos}
                        checked={selectedPozycja.includes(pos)}
                        onChange={() => handlePosChange(pos)}
                        />
                        <label htmlFor={pos}>{pos}</label>
                    </div>
                    ))}
                </div>
                    </div>
                    <div>
                    <button onClick={handleSendDane}>Dodaj oferte</button>
                </div>
                </div>

                
            </div>
    )
}

export default DodajAnkiete;