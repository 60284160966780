import React, {useEffect, useState} from "react";
import './Forgot.css';
import NavbarMain from "../Components/NavbarMain";
import axios from "axios";

function Forgot({title, isadmin}) {
    const apiurl = "https://peopleofqualityback.azurewebsites.net"
    // const apiurl = "http://127.0.0.1:5000";
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    useEffect(() => {
        document.title = title || 'People Of Quality';
      }, [title]);
    const [ResetData, setResetData] = useState({
        password: "",
        repeatPassword: ""
    });
    const handlePasswordChange = () => {
        const email = localStorage.getItem("email")
        // console.log(email)
        if (ResetData.password !== ResetData.repeatPassword) {
            setPasswordMatchError(true);
            return;
          }
        if (ResetData.password !== "" && ResetData.repeatPassword !== ""){
            axios.post(`${apiurl}/api/ChangePassword`, {"email": email, "password":ResetData.password})
        .then( response => {
            if (response.data.message === "Poprawna zmiana hasła") {
                window.alert("Poprawnie zmieniłeś hasło");
                window.location.href = '/Oferty'
            } else {
                console.log("Zmiana hasła się nie powiodła")
                window.alert("Nie udało się zmienić hasła")
            }

        })
        .catch(error => {
            console.error("Wystąpił błąd:", error);
        });
        }
        
    }
    return (
        <div className="Forgot">
            <NavbarMain isadmin = {isadmin}/>

            <div className="containerForgot">
                <div className="inputForgot">
                <input 
                type="password"
                placeholder="Nowe Hasło"
                value={ResetData.password}
                onChange={(e) => {
                    setPasswordMatchError(false);
                    setResetData({
                        ...ResetData,
                        password: e.target.value,

                    });
                }}
                />
                </div>
                <div className="inputForgot">
                <input 
                type="password"
                placeholder="Powtórz Nowe Hasło"
                value={ResetData.repeatPassword}
                onChange={(e) => {
                    setPasswordMatchError(false);
                    setResetData({
                        ...ResetData,
                        repeatPassword: e.target.value,

                    });
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handlePasswordChange()
                    }}} 
                />
                
 
                </div>
            <button className="ForgotPassButton"
            onClick={() => handlePasswordChange()}
            
            >
                Zmień hasło
            </button>
            {passwordMatchError && <div className="error-message">Hasła nie są identyczne</div>}
            </div>
        </div>
    );
}
export default Forgot;